import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ComponentRef,
  OnDestroy,
  Type,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';
import { InsertionDirective } from './insertion.directive';
import { quickFadeAnimation } from '../animations';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  animations: [quickFadeAnimation]
})
export class DialogComponent implements AfterViewInit, OnDestroy {
  private readonly _onClose = new Subject<any>();

  public componentRef!: ComponentRef<any>;
  public childComponentType!: Type<any>;
  public onClose = this._onClose.asObservable();

  // Get a reference to our :
  @ViewChild(InsertionDirective)
  insertionPoint!: InsertionDirective;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private cd: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    this.loadChildComponent(this.childComponentType);
    this.cd.detectChanges();
  }
  ngOnDestroy() {
    // Distroy the child component too
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  loadChildComponent(componentType: Type<any>) {
    // Get a factory for the content compoenent
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      componentType
    );

    //
    const viewContainerRef = this.insertionPoint.viewContainerRef;
    viewContainerRef.clear();

    this.componentRef = viewContainerRef.createComponent(componentFactory);
  }

  onDialogClicked(evt: MouseEvent) {
    // Prevent the overlay click event firing as it is the parent of the dialog
    evt.stopPropagation();
  }
}
