<h2>Choose a Course to Copy from</h2>
<ng-container *ngIf="trainingService.$clubCourses | async as courses">
  <button
    *ngFor="let course of courses; let idx = index"
    class="btn w-full text-center cursor-pointer mt-2"
    [ngStyle]="{
      color: course.colours.fgColour,
      backgroundColor: course.colours.bgColour,
      border: course.colours.bgColour
    }"
    (click)="chooseCourse(course)"
  >
    {{ course.courseName }}
  </button>
</ng-container>
<div class="mt-4 flex justify-end">
  <button class="btn" (click)="onCancel()">Cancel</button>
</div>