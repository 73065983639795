<div
  class="flex flex-col top-0 bottom-0 left-0 right-0 fixed overflow-auto bg-smoke-light"
  @quickFadeAnimation
>
  <div
    class="max-w-screen-md w-[90%] max-h-screen overflow-y-auto relative bg-gray-50 rounded-lg p-3 shadow-2xl shadow-gray-600 my-16 mx-auto"
    (click)="onDialogClicked($event)"
  >
    <ng-template appInsertion></ng-template>
  </div>
</div>
