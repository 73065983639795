import { Component } from '@angular/core';
import { DialogRef } from 'src/app/core/dialog/dialog-ref';
import { DialogConfig } from 'src/app/core/dialog/dialog.config';
import { Course } from 'src/app/models/course.model';

@Component({
  selector: 'app-task-grade-selector',
  templateUrl: './task-grade-selector.component.html',
})
export class TaskGradeSelectorComponent {

  public subTasks: { name: string, id: string }[] = [];
  public step: "tasks" | "subtasks" | "grades" = "tasks";
  public selectedTaskIndex: number = 0;
  public selectedSubTask: boolean = false;
  public selectedSubTaskIndex: number = 0;

  constructor(public config: DialogConfig, private dialog: DialogRef) { }

  selectTask(idx: number) {
    this.selectedTaskIndex = idx;
    if (this.config.data.tasks[idx].subTasks && this.config.data.tasks[idx].subTasks.length) {
      this.step = "subtasks";
      this.subTasks = this.config.data.tasks[idx].subTasks;
      this.selectedSubTask = true;
    } else {
      this.step = "grades";
    }
  }

  selectSubtask(idx: number) {
    this.selectedSubTaskIndex = idx;
    this.step = "grades";
  }

  selectGrade(idx: number) {
    this.dialog.close({
      id: this.config.data.tasks[this.selectedTaskIndex].id,
      name: this.config.data.tasks[this.selectedTaskIndex].name,
      shortResult: this.config.data.taskGrades[idx].shortResult,
      longResult: this.config.data.taskGrades[idx].longResult,
      colours: this.config.data.taskGrades[idx].colours,
      subTask: this.selectedSubTask ? this.config.data.tasks[this.selectedTaskIndex].subTasks[this.selectedSubTaskIndex] : null,
      completed: (idx >= this.config.data.taskGrades.length - 1)
    });
  }
}
