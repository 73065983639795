import { Component, Input, OnInit } from '@angular/core';
import { DialogService } from 'src/app/core/dialog/dialog.service';
import { Course } from 'src/app/models/course.model';
import { TrainingReport } from 'src/app/models/training-report.model';
import { TrainingReportComponent } from '../../training-list/training-report/training-report.component';

@Component({
  selector: 'app-course-summary-competency-map',
  templateUrl: './course-summary-competency-map.component.html',
})
export class CourseSummaryCompetencyMapComponent {

  @Input() records!: TrainingReport[];
  @Input() course!: Course;

  constructor(private dialogService: DialogService) { }

  openReport(report: TrainingReport) {
    this.dialogService.open(TrainingReportComponent, { data: report })
  }

}
