<form [formGroup]="form" (ngSubmit)="onUpdateSettings()">
  <main
    class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4"
    *ngIf="loaded; else loading"
    @fadeAnimation
  >
    <section class="card self-start">
      <h2>General</h2>
      <div class="flex items-baseline mx-2 text-sm">
        <input
          type="checkbox"
          formControlName="enableLog"
          id="enableLog"
        /><label for="enableLog" class="ml-2">Enable Flying Log</label>
      </div>
      <div class="flex items-baseline mx-2 text-sm mb-4">
        <input
          type="checkbox"
          formControlName="enablePilotCreatedReport"
          id="enablePilotCreatedReport"
        /><label for="enablePilotCreatedReport" class="ml-2"
          >Allow Pilots to Create Training Reports</label
        >
      </div>
      <label>Positive Behaviours Caption:</label>
      <input
        type="text"
        class="w-full"
        formControlName="positiveBehavioursText"
      />
      <label>Negative Behaviours Caption:</label>
      <input
        type="text"
        class="w-full"
        formControlName="negativeBehavioursText"
      />
      <label>Minimum Behaviours:</label>
      <input type="number" class="w-full" formControlName="minimumBehaviours" />
      <label>Maximum Behaviours (zero for no limit):</label>
      <input type="number" class="w-full" formControlName="maximumBehaviours" />
    </section>
    <section class="card self-start">
      <h2>Aircraft & Types</h2>
      <button
        class="btn-green inline-flex items-center mb-2"
        (click)="onAddAircraft()"
        type="button"
        tabindex="-1"
      >
        <svg-icon key="add" class="inline-block mr-1"></svg-icon>
        Add Aircraft
      </button>
      <div formArrayName="aircraft">
        <div
          class="mt-4"
          *ngFor="let task of aircraft.controls; let idx = index"
          [formGroupName]="idx"
        >
          <div class="flex items-center">
            <input
              class="flex-1 w-2/6 mr-2"
              type="text"
              formControlName="reg"
              placeholder="G-ABCD"
            />
            <input
              class="flex-1 w-1/2 mr-2"
              type="text"
              formControlName="type"
              placeholder="ASK21"
            />
            <button
              class="icon-button flex-none self-stretch text-red-500"
              (click)="onRemoveAircraft(idx)"
              type="button"
              tabindex="-1"
            >
              <svg-icon key="delete"></svg-icon>
            </button>
          </div>
        </div>
      </div>
    </section>
    <section class="card self-start">
      <h2>Training Stages</h2>
      <button
        class="btn-green inline-flex items-center mb-2"
        (click)="onAddTrainingStage()"
        type="button"
        tabindex="-1"
      >
        <svg-icon key="add" class="inline-block mr-1"></svg-icon>
        Add Training Stage
      </button>
      <div formArrayName="trainingStages">
        <div
          class="mt-4"
          *ngFor="
            let stage of trainingStages.controls;
            let first = first;
            let idx = index
          "
          [formGroupName]="idx"
        >
          <div class="flex items-center">
            <input
              class="flex-1 mr-2"
              type="text"
              formControlName="name"
              placeholder="Name"
            />
            <app-colour-selector
              class="mr-2 self-stretch text-sm"
              formControlName="colours"
            ></app-colour-selector>
            <button
              class="icon-button flex-none self-stretch text-red-500"
              (click)="onRemoveTrainingStage(idx)"
              [ngClass]="{ 'text-gray-400 cursor-not-allowed': first }"
              type="button"
              tabindex="-1"
            >
              <svg-icon key="delete"></svg-icon>
            </button>
          </div>
        </div>
      </div>
    </section>
    <section class="card lg:col-start-2 xl:col-start-3 self-start">
      <button
        class="btn-green w-full justify-center"
        (click)="onUpdateSettings()"
      >
        <svg-icon key="tick" class="inline-block mr-2"></svg-icon
        ><span class="inline-block" type="submit">Update Settings</span>
      </button>
    </section>
  </main>
</form>

<ng-template class="w-full" #loading>
  <app-loading></app-loading>
</ng-template>
