import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/core/dialog/dialog.service';
import { LogService } from 'src/app/core/log.service';
import { CreateFlightComponent } from './create-flight/create-flight.component';

@Component({
  selector: 'app-flight-list',
  templateUrl: './flight-list.component.html',
})
export class FlightListComponent implements OnInit {

  constructor(private dialogService: DialogService, public logService: LogService) { }

  ngOnInit(): void {
  }

  onAddFlight(): void {
    this.dialogService.open(CreateFlightComponent, {}).afterClosed.subscribe((newFlight) => {
      if (newFlight) {
        this.logService.addFlight(newFlight);
      }
    });
  }

}
