<div class="card mb-4">
  <h2>Search Training Records</h2>
  <div class="max-w-screen-md mx-auto">
    <app-pilot-lookup
      [formControl]="pilotSearch"
      label="Pilot:"
    ></app-pilot-lookup>
  </div>
</div>
<ng-container *ngIf="pilotSearch.value">
  <app-training-list
    [userID]="pilotSearch.value.userID"
    [forSelf]="false"
  ></app-training-list>
</ng-container>
