import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { MainComponent } from './main/main.component';
import { canActivate, hasCustomClaim, redirectUnauthorizedTo } from '@angular/fire/compat/auth-guard';
import { AppAdminHomeComponent } from './app-admin/app-admin-home/app-admin-home.component';
import { ClubsComponent } from './app-admin/clubs/clubs.component';
import { TrainingListComponent } from './training/training-list/training-list.component';
import { ClubAdminHomeComponent } from './club-admin/club-admin-home/club-admin-home.component';
import { CoursesComponent } from './club-admin/courses/courses.component';
import { CourseDetailsComponent } from './club-admin/courses/course-details/course-details.component';
import { ClubMembersComponent } from './club-admin/club-members/club-members.component';
import { SettingsComponent } from './settings/settings.component';
import { ClubSettingsComponent } from './club-admin/club-settings/club-settings.component';
import { ForgottenPasswordComponent } from './login/forgotten-password/forgotten-password.component';
import { EmailHandlerComponent } from './login/email-handler/email-handler.component';
import { CreateRecordComponent } from './training/create-record/create-record.component';
import { TrainingLookupComponent } from './training/training-lookup/training-lookup.component';
import { CourseSummaryComponent } from './training/course-summary/course-summary.component';
import { FlightListComponent } from './log/flight-list/flight-list.component';
import { MyLogbookComponent } from './log/my-logbook/my-logbook.component';
import { BookingsHomeComponent } from './bookings/bookings-home/bookings-home.component';
import { ClubBookingsComponent } from './club-admin/club-bookings/club-bookings.component';

const suOnly = () => hasCustomClaim("su");
const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const appRoutes: Routes = [
  {
    path: '',
    component: MainComponent,
    children: [
      { path: '', component: DashboardComponent },
      { path: 'flying-log', component: FlightListComponent },
      { path: 'my-logbook', component: MyLogbookComponent },
      { path: 'bookings', component: BookingsHomeComponent },
      { path: 'my-training', component: TrainingListComponent },
      { path: 'new-training-record', component: CreateRecordComponent },
      { path: 'search-records', component: TrainingLookupComponent },
      { path: 'course-summary', component: CourseSummaryComponent },
      { path: 'settings', component: SettingsComponent },
      {
        path: 'club-admin', component: ClubAdminHomeComponent,
        children: [
          { path: "members", component: ClubMembersComponent },
          { path: "settings", component: ClubSettingsComponent },
          { path: "bookings", component: ClubBookingsComponent },
          {
            path: "courses", component: CoursesComponent
          },
          { path: "courses/details", component: CourseDetailsComponent },
          { path: "courses/create", component: CourseDetailsComponent },
        ]
      },
      {
        path: 'app-admin', component: AppAdminHomeComponent,
        children: [
          { path: "clubs", component: ClubsComponent },
        ]
      },
    ],
    ...canActivate(redirectUnauthorizedToLogin),
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'forgotten-password',
    component: ForgottenPasswordComponent,
  },
  {
    path: 'email-handler',
    component: EmailHandlerComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule { }
