export const environment = {
  production: false,
  useEmulators: false,
  firebase: {
    apiKey: 'AIzaSyDmoTnWcdMf6hi3StxFelZW1GPUI7un0ZA',
    authDomain: 'glidesmart-ee54d.firebaseapp.com',
    projectId: 'glidesmart-ee54d',
    storageBucket: 'glidesmart-ee54d.appspot.com',
    messagingSenderId: '868418445114',
    appId: '1:868418445114:web:9bfaadc5e659d6e578ba03',
    measurementId: 'G-3GLWJ5QMFC',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
