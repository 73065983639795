<main class="grid grid-cols-1 xl:grid-cols-4 gap-4">
  <section class="card self-start">
    <h2>Event Types</h2>
    <button class="btn-green w-full" (click)="onAddEventType()">
      <svg-icon key="add" class="inline-block mr-1"></svg-icon>
      Create Event Type
    </button>
  </section>
  <section class="card xl:col-span-3 self-start">
    <h2>Events</h2>
    <div class="flex flex-row mb-2">
      <button class="btn-green btn-group-left">Add Event</button>
      <button class="btn-outline btn-group-right">Remove Event</button>
    </div>
    <app-calendar></app-calendar>
  </section>
</main>
