<form [formGroup]="form" @fadeAnimation>
  <main class="grid grid-cols-4 gap-4">
    <div class="caution-message text-sm col-span-4" *ngIf="!createMode">
      <span class="font-semibold">Caution:</span> Editing a Course will not make
      any changes to reports that have already been submitted.
    </div>
    <div class="card col-span-4">
      <h2>Course Details</h2>
      <div class="grid grid-cols-2 gap-4">
        <div>
          <label for="courseName">Course Name:</label>
          <input type="text" class="w-full mb-4" formControlName="courseName" />
          <app-colour-selector
            formControlName="colours"
            tabindex="-1"
          ></app-colour-selector>
          <div class="flex items-center mt-2">
            <input type="checkbox" formControlName="hidden" id="hidden" /><label
              for="hidden"
              class="text-sm ml-2"
              >Hide this Course?</label
            >
          </div>
        </div>
        <fieldset>
          <legend class="font-medium">Include:</legend>
          <div class="flex items-center mx-2">
            <input
              type="checkbox"
              formControlName="includeNarrative"
              id="includeNarrative"
            /><label for="includeNarrative" class="text-sm ml-2"
              >Narrative</label
            >
          </div>
          <div class="flex items-center mx-2">
            <input
              type="checkbox"
              formControlName="includeReg"
              id="includeReg"
            /><label for="includeReg" class="text-sm ml-2"
              >Aircraft Type/Registration</label
            >
          </div>
          <div class="flex items-center mx-2">
            <input
              type="checkbox"
              formControlName="includeReview"
              id="includeReview"
            /><label for="includeReview" class="text-sm ml-2"
              >Report for Review</label
            >
          </div>
          <div class="flex items-center mx-2">
            <input
              type="checkbox"
              formControlName="includeTasks"
              id="includeTasks"
            /><label for="includeTasks" class="text-sm ml-2">Tasks</label>
          </div>
          <div class="flex items-center mx-2">
            <input
              type="checkbox"
              formControlName="includeCompetencies"
              id="includeCompetencies"
            /><label for="includeCompetencies" class="text-sm ml-2"
              >Competencies</label
            >
          </div>
        </fieldset>
      </div>
    </div>
    <section
      class="card col-span-4 lg:col-span-2"
      *ngIf="form.get('includeTasks')?.value"
    >
      <h2>Tasks</h2>
      <p class="text-sm">
        Tasks are individually added to a training report. They may be created
        in groups with a main task and multiple sub-tasks.
      </p>
      <div formArrayName="tasks">
        <div
          class="mt-4"
          *ngFor="
            let task of tasks.controls;
            let i = index;
            let lastTask = last;
            let firstTask = first
          "
          [formGroupName]="i"
        >
          <div class="flex items-center">
            <div class="w-16 text-center font-bold" tabindex="-1">
              {{ i + 1 }}
            </div>
            <input class="flex-1 mr-2" type="text" formControlName="name" />
            <button
              class="btn-green-outline text-sm flex-none mr-2"
              (click)="onAddSubTask(i)"
            >
              Add Sub-Task
            </button>
            <button
              tabindex="-1"
              class="icon-button flex-none self-stretch"
              [ngClass]="{ 'text-gray-400': firstTask }"
            >
              <svg-icon key="arrow-up" (click)="move(tasks, i, -1)"></svg-icon>
            </button>
            <button
              tabindex="-1"
              class="icon-button flex-none self-stretch"
              [ngClass]="{ 'text-gray-400': lastTask }"
            >
              <svg-icon key="arrow-down" (click)="move(tasks, i, 1)"></svg-icon>
            </button>
            <button
              tabindex="-1"
              class="icon-button flex-none self-stretch text-red-500"
              (click)="onRemove(tasks, i)"
            >
              <svg-icon key="delete"></svg-icon>
            </button>
          </div>
          <div
            class="ml-16 mt-1"
            *ngFor="
              let item of getSubTasks(i).controls;
              let j = index;
              let lastSubTask = last;
              let firstSubTask = first
            "
            formArrayName="subTasks"
          >
            <div class="flex items-center" [formGroupName]="j">
              <div class="w-10 text-center text-sm" tabindex="-1">
                {{ j + 1 }}
              </div>
              <input
                class="flex-1 mr-2 text-sm"
                type="text"
                formControlName="name"
              />
              <button
                tabindex="-1"
                class="icon-button flex-none self-stretch"
                [ngClass]="{ 'text-gray-400': firstSubTask }"
              >
                <svg-icon
                  key="arrow-up"
                  (click)="move(getSubTasks(i), j, -1)"
                ></svg-icon>
              </button>
              <button
                tabindex="-1"
                class="icon-button flex-none self-stretch"
                [ngClass]="{ 'text-gray-400': lastSubTask }"
              >
                <svg-icon
                  key="arrow-down"
                  (click)="move(getSubTasks(i), j, 1)"
                ></svg-icon>
              </button>
              <button
                tabindex="-1"
                class="icon-button flex-none self-stretch text-red-500"
                (click)="onRemoveSubTask(i, j)"
              >
                <svg-icon key="delete"></svg-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <button class="btn-blue w-full justify-center mt-4" (click)="onAddTask()">
        <svg-icon key="add" class="inline-block mr-2"></svg-icon
        ><span class="inline-block">Add Task</span>
      </button>
    </section>
    <section
      class="card col-span-4 lg:col-span-2"
      *ngIf="form.get('includeTasks')?.value"
    >
      <h2>Task Grades</h2>
      <p class="text-sm">
        Tasks and sub-tasks are graded as follows. At least one grade is
        required. A task will be considered "completed" if the grade at the
        bottom of this list was the most recently awarded.
      </p>
      <p class="text-sm mt-2">
        <a
          class="text-blue-500 underline cursor-pointer"
          (click)="onCopy('taskGrades')"
          >Click here</a
        >
        to copy grades from another course.
      </p>
      <ng-container formArrayName="taskGrades">
        <div
          class="mt-4 flex items-center"
          *ngFor="
            let taskResult of taskGrades.controls;
            let i = index;
            let lastResult = last;
            let firstResult = first
          "
          [formGroupName]="i"
        >
          <input
            class="w-14 mr-2 text-center font-bold"
            type="text"
            formControlName="shortResult"
            placeholder="S"
            maxlength="3"
          />
          <input
            class="flex-1 mr-2"
            type="text"
            formControlName="longResult"
            placeholder="Satisfactory"
          />
          <app-colour-selector
            class="mr-2 self-center text-sm"
            formControlName="colours"
            tabindex="-1"
          ></app-colour-selector>
          <button
            tabindex="-1"
            class="icon-button flex-none self-stretch"
            [ngClass]="{ 'text-gray-400': firstResult }"
          >
            <svg-icon
              key="arrow-up"
              (click)="move(taskGrades, i, -1)"
            ></svg-icon>
          </button>
          <button
            tabindex="-1"
            class="icon-button flex-none self-stretch"
            [ngClass]="{ 'text-gray-400': lastResult }"
          >
            <svg-icon
              key="arrow-down"
              (click)="move(taskGrades, i, 1)"
            ></svg-icon>
          </button>
          <button
            tabindex="-1"
            class="icon-button flex-none self-stretch text-red-500"
            (click)="onRemove(taskGrades, i)"
          >
            <svg-icon key="delete"></svg-icon>
          </button>
        </div>
      </ng-container>
      <button
        class="btn-blue w-full justify-center mt-4"
        (click)="onAddTaskGrade()"
      >
        <svg-icon key="add" class="inline-block mr-2"></svg-icon
        ><span class="inline-block">Add Task Grade</span>
      </button>
    </section>
    <section
      class="card col-span-4 lg:col-span-2"
      *ngIf="form.get('includeCompetencies')?.value"
    >
      <h2>Competenices & Behaviours</h2>
      <p class="text-sm">
        Competencies will always appear on every record from this course. They
        may optionally be associated with behaviours to give more detail when
        assigning a grade.
      </p>
      <div formArrayName="competencies">
        <div
          class="mt-4"
          *ngFor="
            let competency of competencies.controls;
            let i = index;
            let lastCompetency = last;
            let firstCompetency = first
          "
          [formGroupName]="i"
        >
          <div class="flex items-center">
            <input
              class="w-14 mr-2 text-center font-bold"
              type="text"
              formControlName="shortName"
              placeholder="AC"
              maxlength="3"
            />
            <input
              class="flex-1 mr-2"
              type="text"
              formControlName="longName"
              placeholder="Aircraft Control"
            />
            <button
              class="btn-green-outline text-sm flex-none mr-2"
              (click)="onAddBehaviour(i)"
            >
              Add Behaviour
            </button>
            <button
              tabindex="-1"
              class="icon-button flex-none self-stretch"
              [ngClass]="{ 'text-gray-400': firstCompetency }"
            >
              <svg-icon
                key="arrow-up"
                (click)="move(competencies, i, -1)"
              ></svg-icon>
            </button>
            <button
              tabindex="-1"
              class="icon-button flex-none self-stretch"
              [ngClass]="{ 'text-gray-400': lastCompetency }"
            >
              <svg-icon
                key="arrow-down"
                (click)="move(competencies, i, 1)"
              ></svg-icon>
            </button>
            <button
              tabindex="-1"
              class="icon-button flex-none self-stretch text-red-500"
              (click)="onRemove(competencies, i)"
            >
              <svg-icon key="delete"></svg-icon>
            </button>
          </div>
          <div
            class="ml-16 mt-1"
            *ngFor="
              let behaviour of getBehaviours(i).controls;
              let j = index;
              let lastBehaviour = last;
              let firstBehaviour = first
            "
            formArrayName="behaviours"
          >
            <div class="flex items-center">
              <div class="w-10 text-center text-sm" tabindex="-1">
                {{ j + 1 }}
              </div>
              <input
                class="flex-1 mr-2 text-sm"
                type="text"
                [formControlName]="j"
              />
              <button
                tabindex="-1"
                class="icon-button flex-none self-stretch"
                [ngClass]="{ 'text-gray-400': firstBehaviour }"
              >
                <svg-icon
                  key="arrow-up"
                  (click)="move(getBehaviours(i), j, -1)"
                ></svg-icon>
              </button>
              <button
                tabindex="-1"
                class="icon-button flex-none self-stretch"
                [ngClass]="{ 'text-gray-400': lastBehaviour }"
              >
                <svg-icon
                  key="arrow-down"
                  (click)="move(getBehaviours(i), j, 1)"
                ></svg-icon>
              </button>
              <button
                tabindex="-1"
                class="icon-button flex-none self-stretch text-red-500"
                (click)="onRemoveBehaviour(i, j)"
              >
                <svg-icon key="delete"></svg-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        class="btn-blue w-full justify-center mt-4"
        (click)="onAddCompetency()"
      >
        <svg-icon key="add" class="inline-block mr-2"></svg-icon
        ><span class="inline-block">Add Competency</span>
      </button>
    </section>
    <section
      class="card col-span-4 lg:col-span-2"
      *ngIf="form.get('includeCompetencies')?.value"
    >
      <h2>Competency Grades</h2>
      <p class="text-sm">Brief description</p>
      <div formArrayName="competencyGrades">
        <div
          class="mt-4 flex items-center"
          *ngFor="
            let competencyGrade of competencyGrades.controls;
            let i = index;
            let lastGrade = last;
            let firstGrade = first
          "
          [formGroupName]="i"
        >
          <input
            class="w-14 mr-2 text-center font-bold"
            type="text"
            formControlName="shortResult"
            placeholder="C"
            maxlength="3"
          />
          <input
            class="flex-1 w-2/3 mr-2"
            type="text"
            formControlName="longResult"
            placeholder="Correct"
          />
          <select
            class="flex-1 w-1/3 mr-2 self-stretch"
            type="text"
            formControlName="addBehaviours"
          >
            <option [ngValue]="-1">Negative</option>
            <option [ngValue]="0" selected>None</option>
            <option [ngValue]="1">Positive</option>
          </select>
          <app-colour-selector
            class="mr-2 self-stretch text-sm"
            formControlName="colours"
            tabindex="-1"
          ></app-colour-selector>
          <button
            tabindex="-1"
            class="icon-button flex-none self-stretch"
            [ngClass]="{ 'text-gray-400': firstGrade }"
          >
            <svg-icon
              key="arrow-up"
              (click)="move(competencyGrades, i, -1)"
            ></svg-icon>
          </button>
          <button
            tabindex="-1"
            class="icon-button flex-none self-stretch"
            [ngClass]="{ 'text-gray-400': lastGrade }"
          >
            <svg-icon
              key="arrow-down"
              (click)="move(competencyGrades, i, 1)"
            ></svg-icon>
          </button>
          <button
            tabindex="-1"
            class="icon-button flex-none self-stretch text-red-500"
            (click)="onRemove(competencyGrades, i)"
          >
            <svg-icon key="delete"></svg-icon>
          </button>
        </div>
      </div>
      <button
        class="btn-blue w-full justify-center mt-4"
        (click)="onAddCompetencyGrade()"
      >
        <svg-icon key="add" class="inline-block mr-2"></svg-icon
        ><span class="inline-block">Add Competency Grade</span>
      </button>
    </section>
    <section class="card col-start-4">
      <button
        class="btn-green w-full justify-center"
        (click)="onCreateCourse()"
      >
        <svg-icon key="tick" class="inline-block mr-2"></svg-icon
        ><span class="inline-block"
          >{{ createMode ? "Create" : "Update" }} Course</span
        >
      </button>
    </section>
  </main>
</form>
