import { Directive, Input, ViewContainerRef } from "@angular/core";
import { LoadingComponent } from "./loading/loading.component";

@Directive({
  selector: '[ngIfWithLoading]',
})
export class LoadingDirective {
  @Input() set ngIf(val: any) {
    if (!val) {
      this.vcRef.createComponent(LoadingComponent)
    }
  };

  constructor(private vcRef: ViewContainerRef) { }
}