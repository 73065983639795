import { NgModule } from '@angular/core';
import { appAddIcon } from '@app/svg/add';
import { appAddRecordIcon } from '@app/svg/add-record';
import { appAppAdminIcon } from '@app/svg/app-admin';
import { appCalendarIcon } from '@app/svg/calendar';
import { appClubAdminIcon } from '@app/svg/club-admin';
import { appClubsIcon } from '@app/svg/clubs';
import { appDeleteIcon } from '@app/svg/delete';
import { appEditIcon } from '@app/svg/edit';
import { appHomeIcon } from '@app/svg/home';
import { appLandIcon } from '@app/svg/land';
import { appMembersIcon } from '@app/svg/members';
import { appRecordIcon } from '@app/svg/record';
import { appSearchRecordIcon } from '@app/svg/search-record';
import { appSettingsIcon } from '@app/svg/settings';
import { appTakeoffIcon } from '@app/svg/takeoff';
import { appTickIcon } from '@app/svg/tick';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { appDashboardIcon } from 'src/app/svg/dashboard';
import { appFlyableIcon } from 'src/app/svg/flyable';
import { appLoadingSpinnerIcon } from 'src/app/svg/loading-spinner';
import { appLogoutIcon } from 'src/app/svg/logout';
import { appMenuIcon } from 'src/app/svg/menu';
import { appMissingIconIcon } from 'src/app/svg/missing-icon';
import { appSearchIcon } from 'src/app/svg/search';
import { appArrowDownIcon } from '@app/svg/arrow-down';
import { appArrowUpIcon } from '@app/svg/arrow-up';
import { appAddMemberIcon } from '@app/svg/add-member';
import { appMessageIcon } from '@app/svg/message';
import { appCommentIcon } from '@app/svg/comment';
import { appPreviousIcon } from '@app/svg/previous';
import { appNextIcon } from '@app/svg/next';
import { appBookIcon } from '@app/svg/book';

@NgModule({
  declarations: [],
  imports: [
    SvgIconsModule.forRoot({
      icons: [appFlyableIcon,
        appLoadingSpinnerIcon,
        appDashboardIcon,
        appLogoutIcon,
        appSearchIcon,
        appMenuIcon,
        appSettingsIcon,
        appTickIcon,
        appDeleteIcon,
        appTakeoffIcon,
        appLandIcon,
        appCalendarIcon,
        appHomeIcon,
        appAddRecordIcon,
        appRecordIcon,
        appSearchRecordIcon,
        appClubAdminIcon,
        appMembersIcon,
        appAppAdminIcon,
        appClubsIcon,
        appAddIcon,
        appArrowUpIcon,
        appArrowDownIcon,
        appEditIcon,
        appAddMemberIcon,
        appMessageIcon,
        appCommentIcon,
        appPreviousIcon,
        appNextIcon,
        appBookIcon],
      missingIconFallback: appMissingIconIcon
    })
  ],
  exports: [SvgIconsModule]
})
export class IconsModule { }
