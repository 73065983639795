import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
})
export class MainComponent implements OnInit {
  largeNav: boolean = true;
  sideNav: boolean = false;
  floatingNav: boolean = false;

  constructor() {}

  ngOnInit() {
    this.checkCollapse();
  }

  @HostListener('window:resize') onResize() {
    this.checkCollapse();
  }

  private checkCollapse(): void {
    if (window.innerWidth >= 1024) {
      this.largeNav = true;
      this.sideNav = false;
    } else {
      this.largeNav = false;
    }
  }
}
