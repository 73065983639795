<button
  type="button"
  class="btn-blue-outline text-sm"
  [disabled]="disabled"
  [ngClass]="{
    'btn-disabled': disabled
  }"
  *ngIf="buttonVisible"
  (click)="onButtonClick()"
>
  <svg-icon [key]="iconKey" class="mr-2"></svg-icon>{{ label }}
</button>
<div class="w-full flex items-center" *ngIf="!buttonVisible">
  <svg-icon [key]="iconKey" class="mr-4 hidden md:inline"></svg-icon>
  <input
    class="flex-1 w-16 text-center mr-4"
    type="time"
    [formControl]="timeInput"
  />
  <button type="button" class="btn-green-outline text-sm" (click)="onOKClick()">
    OK
  </button>
</div>
