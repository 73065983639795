<ng-container [ngSwitch]="state">
  <ng-container *ngSwitchCase="'search'">
    <div class="flex justify-between items-center">
      <span class="font-semibold tracking-wide">{{ label }}</span>
      <a
        class="text-sm"
        (click)="membershipNumberSearch = !membershipNumberSearch"
      >
        Search by
        {{ !membershipNumberSearch ? "Membership No." : "Surname" }}
      </a>
    </div>
    <div class="flex items-stretch">
      <input
        type="text"
        class="w-full flex-1 mr-2"
        tabindex="1"
        autofocus
        [placeholder]="membershipNumberSearch ? 'Membership No.' : 'Surname'"
        #lookupField
        (keyup.enter)="onFindMember(lookupField.value)"
      />
      <button
        class="btn-blue text-sm flex-none"
        type="button"
        (click)="onFindMember(lookupField.value)"
      >
        <svg-icon key="search" class="inline-block mr-2"></svg-icon> Find
      </button>
    </div>
    <div *ngIf="lookupError" class="text-sm mt-2 warning-message">
      {{ lookupError }}
    </div>
  </ng-container>
  <ng-container *ngSwitchCase="'duplicate'">
    <ng-container *ngIf="$duplicateMembers | async as pilots; else loading">
      <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
        <button
          type="button"
          *ngFor="let pilot of pilots"
          class="btn"
          (click)="onSelectPilot(pilot)"
        >
          {{ pilot.firstName | titlecase }}
          {{ pilot.lastName | titlecase }} ({{
            pilot.membershipNo | uppercase
          }})
        </button>
      </div>
    </ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'result'">
    <div class="flex items-center justify-between">
      <div>
        <span class="font-semibold tracking-wide mr-2">{{ label }}</span
        >{{ value?.firstName | titlecase }}
        {{ value?.lastName | titlecase }} ({{
          value?.membershipNo | uppercase
        }})
        <span
          class="mr-2 text-sm px-2 border rounded"
          [appDisplayColour]="
            value?.trainingStage?.colours || { fgColour: '', bgColour: '' }
          "
          >{{ value?.trainingStage?.name }}</span
        >
      </div>
      <button
        class="btn-blue-outline text-sm flex-none"
        type="button"
        (click)="onCancel()"
      >
        Search Again...
      </button>
    </div>
  </ng-container>
</ng-container>

<ng-template class="w-full" #loading>
  <app-loading></app-loading>
</ng-template>
