import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { ColourSelectorComponent } from './colour-selector/colour-selector.component';
import { ColourSelectorDialogComponent } from './colour-selector/colour-selector-dialog/colour-selector-dialog.component';
import { LoadingComponent } from './loading/loading.component';
import { DialogModule } from './dialog/dialog.module';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { StepperComponent } from './stepper/stepper.component';
import { PilotLookupComponent } from './forms/pilot-lookup/pilot-lookup.component';
import { DateInputConverter } from './forms/DateInputConverter.directive';
import { TableComponent } from './table/table.component';
import { LoadingDirective } from './loading.directive';
import { DisplayColourDirective } from './display-colour.directive';
import { UserDatePipe } from './userDate.pipe';
import { SvgIconsModule } from '@ngneat/svg-icon';
import { PaginatorComponent } from './forms/paginator/paginator.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IconsModule } from './icons/icons.module';
import { CallbackPipe } from './callback.pipe';
import { TimeSelectorComponent } from './forms/time-selector/time-selector.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarEventComponent } from './calendar/calendar-event/calendar-event.component';
import { SelectListComponent } from './forms/select-list/select-list.component';

@NgModule({
  declarations: [
    PaginatorComponent,
    ColourSelectorComponent,
    ColourSelectorDialogComponent,
    LoadingComponent,
    StepperComponent,
    PilotLookupComponent,
    DateInputConverter,
    TableComponent,
    LoadingDirective,
    DisplayColourDirective,
    UserDatePipe,
    CallbackPipe,
    TimeSelectorComponent,
    CalendarComponent,
    CalendarEventComponent,
    SelectListComponent,
  ],
  imports: [
    CommonModule,
    AngularFireAuthModule,
    AngularFirestoreModule,
    ReactiveFormsModule,
    CdkStepperModule,
    IconsModule,
  ],
  exports: [
    SvgIconsModule,
    PaginatorComponent,
    ColourSelectorComponent,
    LoadingComponent,
    DialogModule,
    StepperComponent,
    CdkStepperModule,
    PilotLookupComponent,
    DateInputConverter,
    LoadingDirective,
    DisplayColourDirective,
    UserDatePipe,
    IconsModule,
    CallbackPipe,
    TimeSelectorComponent,
    CalendarComponent,
    SelectListComponent
  ],
})
export class CoreModule { }
