<form [formGroup]="form">
  <app-stepper linear #trainingRecordStepper [hideNavigation]="true">
    <cdk-step
      label="Create New Training Report"
      editable="false"
      completed="selectedCourse"
    >
      <ng-container
        *ngIf="
          trainingService.$clubCourses | async as courses;
          withLoadingAsync
        "
      >
        <div
          class="max-w-screen-md mx-auto flex items-stretch"
          *ngIf="clubService.clubOptions?.enablePilotCreatedReport"
        >
          <p class="text-sm font-medium flex-none self-center mr-4">
            Create this report for:
          </p>
          <button
            type="button"
            class="btn-outline flex-1 form-group-left text-sm justify-center"
            [ngClass]="{ 'text-blue-500 font-bold': createForSelf }"
            (click)="createForSelf = true"
          >
            Me
          </button>
          <button
            type="button"
            class="btn-outline flex-1 form-group-right text-sm justify-center"
            [ngClass]="{ 'text-blue-500 font-bold': !createForSelf }"
            (click)="createForSelf = false"
          >
            Another Pilot
          </button>
        </div>
        <p class="max-w-screen-md mx-auto mt-4 font-semibold tracking-wide">
          Choose a course:
        </p>
        <div
          class="max-w-screen-md mx-auto mt-2 grid grid-cols-1 md:grid-cols-2 gap-2"
        >
          <button
            type="button"
            *ngFor="let course of courses"
            class="btn border"
            (click)="onSelectCourse(course)"
            [appDisplayColour]="course.colours"
          >
            {{ course.courseName }}
          </button>
        </div>
      </ng-container>
    </cdk-step>
    <cdk-step label="General" [stepControl]="otherPilot">
      <div class="flex flex-col max-w-screen-md mx-auto">
        <app-pilot-lookup
          [formControl]="otherPilot"
          [label]="createForSelf ? 'Report to be Verified by:' : 'Trainee:'"
        ></app-pilot-lookup>
        <label class="mt-2" for="formDate">Form Date:</label>
        <input
          type="date"
          class="w-full"
          formControlName="date"
          appDateConverter
        />
        <label class="mt-2" for="aircraft" *ngIf="selectedCourse?.includeReg"
          >Aircraft:</label
        >
        <select
          class="w-full"
          formControlName="aircraft"
          *ngIf="selectedCourse?.includeReg"
        >
          <option [ngValue]="null"></option>
          <option
            [ngValue]="aircraft"
            *ngFor="let aircraft of clubService.clubOptions?.aircraft"
          >
            {{ aircraft.reg }} ({{ aircraft.type }})
          </option>
        </select>
        <div class="flex justify-between items-stretch mt-4">
          <button type="button" class="btn-outline" (click)="onCancel()">
            Start Over
          </button>
          <button
            type="button"
            class="btn-outline"
            [ngClass]="{
              'btn-green':
                otherPilot.valid &&
                form.get('date')?.valid &&
                (selectedCourse?.includeReg
                  ? form.get('aircraft')?.valid
                  : true)
            }"
            cdkStepperNext
          >
            Next
            <svg-icon key="next" class="inline-block ml-2"></svg-icon>
          </button>
        </div>
      </div>
    </cdk-step>
    <cdk-step
      label="Tasks"
      *ngIf="selectedCourse && selectedCourse.includeTasks"
      [stepControl]="form.get('gradedTasks')!"
    >
      <div class="max-w-screen-md mx-auto">
        <button
          class="btn-blue w-full justify-center mb-2"
          (click)="onAddTask()"
        >
          <svg-icon key="add" class="inline-block mr-2"></svg-icon
          ><span class="inline-block">Add Task</span>
        </button>
        <app-task
          *ngFor="let item of gradedTasks.controls; let idx = index"
          [task]="item.value"
          [delete]="true"
          (onDelete)="onRemoveTask(idx)"
        ></app-task>
        <div class="flex justify-between items-stretch mt-4">
          <button type="button" class="btn-outline" cdkStepperPrevious>
            <svg-icon key="previous" class="inline-block mr-2"></svg-icon>
            Previous
          </button>
          <button
            type="button"
            class="btn-outline"
            [ngClass]="{
              'btn-green': gradedTasks.valid
            }"
            cdkStepperNext
          >
            Next
            <svg-icon key="next" class="inline-block ml-2"></svg-icon>
          </button>
        </div>
      </div>
    </cdk-step>
    <cdk-step
      formArrayName="gradedCompetencies"
      *ngFor="
        let competency of selectedCourse?.competencies;
        let competencyIndex = index
      "
      [label]="competency.longName"
      [stepControl]="gradedCompetencies.get([competencyIndex])!"
    >
      <div class="max-w-screen-md mx-auto">
        <app-competency-grade-selector
          class="max-w-screen-md mx-auto"
          [formControlName]="competencyIndex"
          [competency]="competency"
          [competencyGrades]="selectedCourse?.competencyGrades"
        ></app-competency-grade-selector>
        <div class="flex justify-between items-stretch mt-4">
          <button type="button" class="btn-outline" cdkStepperPrevious>
            <svg-icon key="previous" class="inline-block mr-2"></svg-icon>
            Previous
          </button>
          <button
            type="button"
            class="btn-outline"
            [ngClass]="{
              'btn-green': gradedCompetencies.get([competencyIndex])!.valid
            }"
            cdkStepperNext
          >
            Next
            <svg-icon key="next" class="inline-block ml-2"></svg-icon>
          </button>
        </div>
      </div>
    </cdk-step>
    <cdk-step
      label="Narrative"
      *ngIf="selectedCourse && selectedCourse.includeNarrative"
    >
      <div class="max-w-screen-md mx-auto">
        <textarea
          class="w-full"
          rows="6"
          formControlName="narrative"
        ></textarea>
        <div class="flex justify-between items-stretch mt-4">
          <button type="button" class="btn-outline" cdkStepperPrevious>
            <svg-icon key="previous" class="inline-block mr-2"></svg-icon>
            Previous
          </button>
          <button type="button" class="btn-green" cdkStepperNext>
            Next
            <svg-icon key="next" class="inline-block ml-2"></svg-icon>
          </button>
        </div>
      </div>
    </cdk-step>
    <cdk-step label="Save Report">
      <div class="max-w-screen-md mx-auto">
        <input
          type="checkbox"
          formControlName="requireReview"
          id="requireReview"
        /><label for="requireReview" class="text-sm ml-2"
          >Report for Review?</label
        >
        <app-pilot-lookup
          *ngIf="form.get('requireReview')?.value"
          formControlName="reviewerID"
          label="Report for Review by:"
        ></app-pilot-lookup>
        <div class="mt-4">
          <button type="button" class="btn-outline" (click)="onReportPreview()">
            <svg-icon key="record" class="inline-block mr-2"></svg-icon>
            Preview Report
          </button>
        </div>
        <div class="flex justify-between items-stretch mt-4">
          <button type="button" class="btn-outline" cdkStepperPrevious>
            <svg-icon key="previous" class="inline-block mr-2"></svg-icon>
            Previous
          </button>
          <button type="button" class="btn-green" (click)="onSubmit()">
            Submit
            <svg-icon key="tick" class="inline-block ml-2"></svg-icon>
          </button>
        </div>
      </div>
    </cdk-step>
  </app-stepper>
</form>
