<h2>Add Club</h2>
<form [formGroup]="form">
  <div class="form-group">
    <label for="clubName">Club Name:</label>
    <input type="text" id="clubName" formControlName="clubName" />
  </div>
  <div class="form-group">
    <label for="location">Location:</label>
    <input type="text" id="location" formControlName="location" />
  </div>
  <div class="form-group">
    <label for="firstName">Admin First Name:</label>
    <input type="text" id="firstName" formControlName="firstName" />
  </div>
  <div class="form-group">
    <label for="lastName">Admin Last Name:</label>
    <input type="text" id="lastName" formControlName="lastName" />
  </div>
  <div class="form-group">
    <label for="phone">Phone:</label>
    <input type="text" id="phone" formControlName="phone" />
  </div>
  <div class="form-group">
    <label for="website">Website:</label>
    <input type="text" id="website" formControlName="website" />
  </div>
  <div class="form-group">
    <label for="adminEmail">Admin Email:</label>
    <input type="text" id="adminEmail" formControlName="adminEmail" />
  </div>
</form>
<div class="flex justify-end mt-4">
  <button class="btn-outline btn-group-left" (click)="onCancel()">Cancel</button>
  <button class="btn-green btn-group-right" (click)="onSave()">
    Add Club
  </button>
</div>
