import { Component, OnInit } from '@angular/core';
import { DialogRef } from '../dialog-ref';
import { DialogConfig } from '../dialog.config';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css'],
})
export class AlertComponent implements OnInit {
  constructor(public config: DialogConfig, public dialog: DialogRef) {}

  ngOnInit(): void {}

  onClose(result: boolean) {
    this.dialog.close(result);
  }
}
