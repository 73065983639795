import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlightListComponent } from './flight-list/flight-list.component';
import { FlightListEntryComponent } from './flight-list/flight-list-entry/flight-list-entry.component';
import { MyLogbookComponent } from './my-logbook/my-logbook.component';
import { CreateFlightComponent } from './flight-list/create-flight/create-flight.component';
import { CoreModule } from '../core/core.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    FlightListComponent,
    FlightListEntryComponent,
    CreateFlightComponent,
    MyLogbookComponent,
  ],
  imports: [
    CommonModule,
    CoreModule,
    ReactiveFormsModule
  ],
  exports: [FlightListEntryComponent]
})
export class LogModule { }
