<main class="grid grid-cols-1 md:grid-cols-4 gap-4">
  <div class="card col-span-2 self-start">
    <h2>My Details</h2>
    <ng-container *ngIf="authService.$userDoc | async as user; withLoading">
      <p>
        Stage of Training:
        <span
          class="rounded-md px-2 py-1 text-sm"
          [ngStyle]="{
            color:
              user.clubs[user.selectedClubIndex].trainingStage.colours.fgColour,
            backgroundColor:
              user.clubs[user.selectedClubIndex].trainingStage.colours.bgColour
          }"
          >{{ user.clubs[user.selectedClubIndex].trainingStage.name }}</span
        >
      </p>
      <p>Last Flight:</p>
      <p>Last Training Record:</p></ng-container
    >
  </div>
  <div class="card col-span-2 self-start">
    <h2>Messages</h2>
    <ng-container
      *ngIf="authService.$messages | async as messages; withLoading"
    >
      <article
        *ngFor="let message of messages; let firstMessage = first"
        class="py-2 px-4 border border-l-8 rounded-md shadow-sm hover:shadow-md flex items-start cursor-pointer"
        [ngStyle]="{ borderLeftColor: message.colour }"
        [ngClass]="{ 'mt-4': !firstMessage }"
        (click)="onMessageClick(message)"
      >
        <div>
          {{ message.date.toDate() | date: "dd MMM" }}
        </div>
        <div class="ml-4 flex-1 self-center">
          <p class="font-semibold">{{ message.title }}</p>
          <p class="text-sm">
            {{ message.message }}
          </p>
        </div>
      </article>
    </ng-container>
  </div>
</main>
