import { Component } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { fadeAnimation } from 'src/app/core/animations';
import { AuthService } from 'src/app/core/auth.service';
import { ClubService } from 'src/app/core/club.service';
import { DialogRef } from 'src/app/core/dialog/dialog-ref';
import { Role } from 'src/app/models/user.model';

@Component({
  selector: 'app-add-member-dialog',
  templateUrl: './add-member-dialog.component.html',
  animations: [fadeAnimation]
})

export class AddMemberDialogComponent {
  role = Role;
  public form: FormGroup;
  private addMemberRequest = this.fns.httpsCallable('addMember');

  constructor(
    private dialog: DialogRef,
    private fns: AngularFireFunctions,
    public clubService: ClubService,
    private authService: AuthService,
  ) {
    this.form = new FormGroup({
      email: new FormControl(null, [Validators.required, Validators.email]),
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      membershipNo: new FormControl(null, [Validators.required]),
      trainingStage: new FormControl(null, [Validators.required]),
      role: new FormControl(null, [Validators.required]),
      clubID: new FormControl(null),
    })
  }

  onCancel() {
    this.dialog.close();
  }

  async onSave() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      try {
        this.form.controls.clubID.setValue(this.authService.user?.clubs[this.authService.user.selectedClubIndex].clubID);
        const res = await firstValueFrom(this.addMemberRequest(this.form.value));
        this.dialog.close();
      } catch {

      }
    }
  }
}

