<h2>Text Colour</h2>
<div class="flex flex-col w-full">
  <div class="flex justify-evenly">
    <button
      class="text-center h-8 lg:h-10 rounded-full"
      (click)="selectText('#F9FAFB')"
    >
      White
    </button>
  </div>
  <div *ngFor="let group of colours" class="flex w-full justify-between mt-2">
    <button
      class="text-center h-6 md:h-8 lg:h-10 w-6 md:w-8 lg:w-10 rounded-full"
      *ngFor="let colour of group"
      [ngStyle]="{ backgroundColor: colour }"
      (click)="selectText(colour)"
    ></button>
  </div>
</div>

<h2 class="mt-6">Background Colour</h2>
<div class="flex flex-col w-full">
  <div class="flex justify-center">
    <button
      class="text-center h-8 lg:h-10 rounded-full px-4 border border-gray-900 mr-8"
      (click)="selectBackground('transparent')"
    >
      Border
    </button>
    <button
      class="text-center h-8 lg:h-10 rounded-full"
      (click)="selectBackground('#F9FAFB')"
    >
      White
    </button>
  </div>
  <div *ngFor="let group of colours" class="flex w-full justify-between mt-2">
    <button
      class="text-center h-6 md:h-8 lg:h-10 w-6 md:w-8 lg:w-10 rounded-full"
      *ngFor="let colour of group"
      [ngStyle]="{ backgroundColor: colour }"
      (click)="selectBackground(colour)"
    ></button>
  </div>
</div>

<div class="mt-6 flex mx-auto justify-center">
  <button class="btn border" [appDisplayColour]="value">Example Item</button>
</div>

<div class="flex justify-end mt-4">
  <button class="btn-green" (click)="onClose()">OK</button>
</div>
