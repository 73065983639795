import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef } from 'src/app/core/dialog/dialog-ref';

@Component({
  selector: 'app-add-club',
  templateUrl: './add-club-dialog.component.html',
})
export class AddClubDialogComponent {

  public form: FormGroup;
  public error: boolean = false;
  private addClubRequest = this.fns.httpsCallable('addClub');

  constructor(
    private dialog: DialogRef,
    private fns: AngularFireFunctions
  ) {
    this.form = new FormGroup({
      clubName: new FormControl(null, [Validators.required, Validators.minLength(3)]),
      location: new FormControl(null, [Validators.required]),
      phone: new FormControl(null, [Validators.required]),
      firstName: new FormControl(null, [Validators.required]),
      lastName: new FormControl(null, [Validators.required]),
      website: new FormControl(null),
      adminEmail: new FormControl(null, [Validators.required, Validators.email]),
    })
  }

  onCancel() {
    this.dialog.close();
  }

  async onSave() {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      const addedClub = await this.addClubRequest(this.form.value).toPromise()
      console.log(addedClub);
      // FIXME: Fix
    } else {
      this.error = true;
    }
  }
}
