import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { gradedTask } from 'src/app/models/training-report.model';

@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
})
export class TaskComponent {

  @Input() task!: gradedTask;
  @Input() delete: boolean = false;
  @Output() onDelete = new EventEmitter<boolean>()

  constructor() { }

  clickDelete() {
    this.onDelete.emit(true);
  }

}
