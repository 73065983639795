<div
  [ngSwitch]="mode"
  class="rounded-2xl bg-gray-50 p-4 lg:p-8 max-w-md m-4 mx-auto lg:mt-12 shadow-2xl drop-shadow-2xl"
>
  <ng-container *ngSwitchCase="'resetPassword'">
    <h1>Password Reset</h1>
    <main class="flex-col items-stretch">
      <form [formGroup]="resetForm" (submit)="onResetPassword()">
        <div class="mt-2">
          <label> New Password:</label>
        </div>
        <input
          type="password"
          autocomplete="new-password"
          class="w-full mt-2"
          formControlName="password"
        />
        <label> Confirm Password:</label>
        <input
          type="password"
          autocomplete="new-password"
          class="w-full mt-2"
          formControlName="confirmPassword"
        />
        <div *ngIf="hasError" class="text-sm mt-4 warning-message">
          {{ hasError }}
        </div>
        <button
          class="btn-blue w-full mt-4 justify-center"
          type="button"
          (click)="onResetPassword()"
        >
          Reset Password
        </button>
      </form>
    </main>
  </ng-container>
  <ng-container *ngSwitchCase="'verifyEmail'">verify-email works</ng-container>
  <ng-container *ngSwitchDefault>
    <h1>Something went Wrong</h1>
    <p>
      Something went wrong! To return to the login page,
      <a routerLink="/login" class="link">click here</a>.
    </p>
  </ng-container>
</div>
