import { Component, OnInit } from '@angular/core';
import { DisplayColour } from 'src/app/models/display-colour.model';
import { DialogRef } from '../../dialog/dialog-ref';
import { DialogConfig } from '../../dialog/dialog.config';

@Component({
  selector: 'app-colour-selector-dialog',
  templateUrl: './colour-selector-dialog.component.html',
})
export class ColourSelectorDialogComponent {

  colours = [
    ["#E5E7EB", "#6B7280", "#fee2e2", "#ffedd5", "#fef9c3", "#dcfce7", "#cffafe", "#dbeafe", "#f3e8ff", "#fce7f3"], // 100
    ["#D1D5DB", "#374151", "#fca5a5", "#fdba74", "#fde047", "#86efac", "#67e8f9", "#93c5fd", "#d8b4fe", "#f9a8d4"], // 500
    ["#9ca3af", "#111827", "#ef4444", "#f97316", "#eab308", "#22c55e", "#06b6d4", "#3b82f6", "#a855f7", "#ec4899"],
  ]

  bgColours = ["transparent", "#E5E7EB", "#D1D5DB", "#9ca3af", "#6B7280", "#374151", "#111827",
    "#FEE2E2", "#FEF3C7", "#DCFCE7", "#DBEAFE", "#F3E8FF",
    "#EF4444", "#FBBF24", "#22C55E", "#3B82F6", "#A855F7"]

  public value: DisplayColour;

  constructor(private config: DialogConfig, private dialog: DialogRef) {
    this.value = config.data;
  }

  selectText(val: string) {
    this.value.fgColour = val;
  }
  selectBackground(val: string) {
    this.value.bgColour = val;
  }

  getCaption(colour: string) {
    if (colour === "transparent") return "None";
    if (colour === "#F9FAFB") return "White";
    return "";
  }

  onClose() {
    if (!this.value.bgColour) {
      this.value.bgColour = "transparent";
    }
    if (!this.value.fgColour) {
      this.value.fgColour = "#111827";
    }
    this.dialog.close(this.value)
  }

}
