import { Component, OnInit } from '@angular/core';
import * as TimeHelpers from '../time-helpers';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
})
export class CalendarComponent implements OnInit {

  public daysOfMonth: string[] = [];
  public blanks = 0;
  public currentMonthText: string | null = "";
  public currentYearText: string = "";
  public today = new Date();

  private currentMonth: number;
  private currentYear: number;

  constructor() {
    this.currentMonth = this.today.getMonth() + 1;
    this.currentYear = this.today.getFullYear();
  }
  ngOnInit(): void {
    this.updateCalendar();
  }

  updateCalendar() {
    this.currentMonthText = TimeHelpers.MONTHS[this.currentMonth];
    this.currentYearText = this.currentYear.toString().substring(2);

    this.daysOfMonth = new Array<string>(
      TimeHelpers.getDaysInMonth(this.currentYear, this.currentMonth))

    this.blanks =
      TimeHelpers.getDayofMonth(this.currentYear, this.currentMonth, 1) - 1;
  }

  onAddMonth() {
    if (this.currentMonth >= 12) {
      this.currentMonth = 1;
      this.currentYear++;
    } else {
      this.currentMonth++;
    }
    this.updateCalendar();
  }

  onSubtractMonth() {
    if (this.currentMonth <= 1) {
      this.currentMonth = 12;
      this.currentYear--;
    } else {
      this.currentMonth--;
    }
    this.updateCalendar();
  }
}
