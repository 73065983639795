import {
  Injectable,
  ComponentFactoryResolver,
  ApplicationRef,
  Injector,
  ComponentRef,
  EmbeddedViewRef,
  Type,
} from '@angular/core';
import { DialogRef } from './dialog-ref';
import { DialogComponent } from './dialog.component';
import { DialogConfig } from './dialog.config';
import { DialogInjector } from './dialog.injector';
import { DialogModule } from './dialog.module';

@Injectable({
  providedIn: DialogModule,
})
export class DialogService {
  // Holds a reference to the dialog component that we will create
  dialogComponentRef!: ComponentRef<DialogComponent>;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector
  ) {}

  public open(componentType: Type<any>, config: DialogConfig) {
    const dialogRef = this.appendDialogComponentToBody(config);

    this.dialogComponentRef.instance.childComponentType = componentType;

    return dialogRef;
  }

  private appendDialogComponentToBody(config: DialogConfig) {
    // create a map with the config
    const map = new WeakMap();
    map.set(DialogConfig, config);

    // add the DialogRef to dependency injection
    const dialogRef = new DialogRef();
    map.set(DialogRef, dialogRef);

    // we want to know when somebody called the close mehtod
    const sub = dialogRef.afterClosed.subscribe(() => {
      // close the dialog
      this.removeDialogComponentFromBody();
      sub.unsubscribe();
    });

    // Get a factory to build a dialog component
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      DialogComponent
    );

    // Create an instance of the dialog component, and pass an injector, so the new component can used Dependancy Injection
    // use our new injector
    const componentRef = componentFactory.create(
      new DialogInjector(this.injector, map)
    );

    // Attach to Angulars component tree (different to DOM)
    this.appRef.attachView(componentRef.hostView);

    // Get the root DOM element and attach to the HTML body
    const domElem = (componentRef.hostView as EmbeddedViewRef<any>)
      .rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    // Store the referent to our created dialog
    this.dialogComponentRef = componentRef;

    // return the dialogRef
    return dialogRef;
  }

  private removeDialogComponentFromBody() {
    this.appRef.detachView(this.dialogComponentRef.hostView);
    this.dialogComponentRef.destroy();
  }
}
