import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-training-lookup',
  templateUrl: './training-lookup.component.html',
})
export class TrainingLookupComponent implements OnInit {

  public pilotSearch: FormControl = new FormControl(null);

  constructor() { }

  ngOnInit(): void {

  }

}
