<div
  class="inline cursor-pointer mr-2 rounded border px-2 text-center align-middle align-middle"
  (click)="expand = !expand"
  [appDisplayColour]="eventDetails.details.colours"
  *ngIf="!expand"
>
  {{ eventDetails.details.shortResult }}
</div>

<div
  class="inline cursor-pointer rounded-l border border-r-0 px-2 text-center align-middle"
  [appDisplayColour]="eventDetails.details.colours"
  (click)="expand = !expand"
  *ngIf="expand"
>
  {{ eventDetails.details.longResult }}
</div>
<div
  class="inline cursor-pointer mr-2 rounded-r border px-2 text-center !bg-gray-50 !text-gray-700 align-middle"
  [appDisplayColour]="eventDetails.details.colours"
  *ngIf="expand"
>
  <span class="text-sm mx-2">{{ eventDetails.date | userDate }}</span
  ><a class="text-sm" (click)="openReport(eventDetails.report)">View Report</a>
</div>
<!-- 
<div *ngIf="expand" class="table-cell">
  <div
    class="inline cursor-pointer rounded-l border px-2"
    (click)="expand = !expand"
    [appDisplayColour]="eventDetails.details.colours"
  >
    {{ eventDetails.details.longResult }}
  </div>
  <div
    class="inline border border-l-0 mr-2 rounded-r !bg-gray-50 !text-gray-700"
    [appDisplayColour]="eventDetails.details.colours"
  >
    <span class="cursor-pointer italic text-sm mx-2">{{
      eventDetails.date | userDate
    }}</span
    ><a class="text-sm mr-2" (click)="openReport(eventDetails.report)"
      >View Report</a
    >
  </div>
</div> -->
