import { CdkStepper } from '@angular/cdk/stepper';
import { Component, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { fadeAnimation } from 'src/app/core/animations';
import { AuthService } from 'src/app/core/auth.service';
import { ClubService } from 'src/app/core/club.service';
import { DialogService } from 'src/app/core/dialog/dialog.service';
import { TrainingService } from 'src/app/core/training.service';
import { Course } from 'src/app/models/course.model';
import { TrainingReportComponent } from '../training-list/training-report/training-report.component';
import { TaskGradeSelectorComponent } from './task-grade-selector/task-grade-selector.component';

@Component({
  selector: 'app-create-record',
  templateUrl: './create-record.component.html',
  animations: [fadeAnimation]
})
export class CreateRecordComponent {
  public selectedCourse?: Course;
  public form: FormGroup;
  public otherPilot: FormControl = new FormControl(null, Validators.required);
  public createForSelf: boolean = false;

  @ViewChild('trainingRecordStepper')
  trainingRecordStepper!: CdkStepper;

  constructor(public authService: AuthService,
    public clubService: ClubService,
    public trainingService: TrainingService,
    private dialogService: DialogService,
    private router: Router) {
    this.form = new FormGroup({
      date: new FormControl(new Date()),
      courseName: new FormControl(null, Validators.required),
      courseID: new FormControl(null, Validators.required),
      colour: new FormControl(null, Validators.required),
      gradedTasks: new FormArray([]),
      gradedCompetencies: new FormArray([]),
      requireReview: new FormControl(false, Validators.required),
      reviewerID: new FormControl(null,),
      narrative: new FormControl(null),
      comments: new FormArray([]),
    });
  }

  onCancel() {
    this.trainingRecordStepper.reset();
    this.form.reset();
  }

  onSelectCourse(course: Course) {
    this.selectedCourse = course;

    if (this.selectedCourse.includeReg) {
      this.form.addControl("aircraft", new FormControl(null, Validators.required));
    }

    this.form.controls.courseID.setValue(this.selectedCourse.courseID, { emitEvent: false });
    this.form.controls.courseName.setValue(this.selectedCourse.courseName, { emitEvent: false });
    this.form.controls.colour.setValue(this.selectedCourse.colours, { emitEvent: false });

    this.selectedCourse.competencies.forEach(competency => {
      this.gradedCompetencies.push(new FormControl(), { emitEvent: false })
    })

    this.form.updateValueAndValidity();

    this.trainingRecordStepper.next()
  }

  onAddTask() {
    this.dialogService.open(TaskGradeSelectorComponent, { data: this.selectedCourse }).afterClosed.subscribe(result => {
      this.gradedTasks.push(new FormControl(result));
    })
  }

  onRemoveTask(idx: number) {
    this.gradedTasks.removeAt(idx);
  }

  onReportPreview() {
    this.dialogService.open(TrainingReportComponent, { data: this.form.value })
  }

  async onSubmit() {
    // FIXME: Check validity
    if (this.form.valid && this.otherPilot.valid) {
      const res = await firstValueFrom(this.trainingService.createRecord(this.form.value, this.createForSelf, this.otherPilot.value));
      this.router.navigate([""])
    }
  }

  get gradedCompetencies(): FormArray {
    return <FormArray>this.form.get("gradedCompetencies");
  }

  get gradedTasks(): FormArray {
    return <FormArray>this.form.get("gradedTasks");
  }
  formValue(path: string | (string | number)[]): any {
    return this.form.get(path)?.value
  }


}
