import { Directive, DoCheck, HostBinding, Input, OnChanges, OnInit, SimpleChanges, ElementRef, KeyValueChanges, KeyValueDiffer, KeyValueDiffers, Renderer2 } from '@angular/core';
import { DisplayColour } from '../models/display-colour.model';

@Directive({
  selector: '[appDisplayColour]'
})
export class DisplayColourDirective implements DoCheck {

  private _value: DisplayColour | null = null;
  private _lastValue: DisplayColour | null = { fgColour: "", bgColour: "" };

  @HostBinding("style.backgroundColor") bgColour!: string | null;
  @HostBinding("style.color") fgColour!: string | null;
  @HostBinding("style.borderColor") borderColour!: string | null;


  @Input('appDisplayColour')
  set colour(values: DisplayColour) {
    this._value = values;
  }

  constructor() { }

  ngDoCheck(): void {
    if (this._lastValue && this._value) {
      if (this._value?.bgColour !== this._lastValue?.bgColour || this._value?.fgColour !== this._lastValue?.fgColour) {
        this.updateColours(this._value);
        this._lastValue.fgColour = this._value.fgColour;
        this._lastValue.bgColour = this._value.bgColour;
      }
    }
  }

  private updateColours(v: DisplayColour) {
    this.fgColour = v.fgColour !== "" ? v.fgColour : null;
    this.bgColour = v.bgColour !== "" ? v.bgColour : null;
    this.fgColour = v.fgColour;
    if (this.bgColour === "transparent") {
      this.borderColour = v.fgColour;
    } else {
      this.borderColour = v.bgColour;
    }
  }


}
