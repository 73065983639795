import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-bookings-home',
  templateUrl: './bookings-home.component.html',
})
export class BookingsHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
