<nav class="mb-4 mt-1 inline-flex">
  <button
    class="btn-outline btn-group-left bg-white"
    routerLink="/club-admin/members"
    routerLinkActive="text-blue-600 font-medium"
  >
    <svg-icon key="members" class="mr-2"></svg-icon>
    Members
  </button>
  <button
    class="btn-outline btn-group-middle bg-white"
    routerLink="/club-admin/courses"
    routerLinkActive="text-blue-600 font-medium"
  >
    <svg-icon key="record" class="mr-2"></svg-icon>
    Training Courses
  </button>
  <button
    class="btn-outline btn-group-middle bg-white"
    routerLink="/club-admin/bookings"
    routerLinkActive="text-blue-600 font-medium"
  >
    <svg-icon key="calendar" class="mr-2"></svg-icon>
    Bookings
  </button>
  <button
    class="btn-outline btn-group-right bg-white"
    routerLink="/club-admin/settings"
    routerLinkActive="text-blue-600 font-medium"
  >
    <svg-icon key="settings" class="mr-2"></svg-icon>
    Club Settings
  </button>
</nav>

<main class="h-full">
  <router-outlet></router-outlet>
</main>
