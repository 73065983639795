import { Component, Input, OnInit } from '@angular/core';
import { Timestamp } from '@firebase/firestore-types';
import { DialogService } from 'src/app/core/dialog/dialog.service';
import { gradedTask, TrainingReport } from 'src/app/models/training-report.model';
import { TrainingReportComponent } from 'src/app/training/training-list/training-report/training-report.component';

@Component({
  selector: 'app-course-summary-task-grade',
  templateUrl: './course-summary-task-grade.component.html',
})
export class CourseSummaryTaskGradeComponent {

  @Input()
  eventDetails!: { date: Timestamp; details: gradedTask, report: TrainingReport; };

  public expand = false;

  constructor(private dialogService: DialogService) { }

  openReport(report: TrainingReport) {
    this.dialogService.open(TrainingReportComponent, { data: report })
  }

}
