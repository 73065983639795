import { Time } from '@angular/common';

export const MONTHS = [
  null,
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export function timeDifference(t1: Time, t2: Time): number | null {
  if (
    t1.hours === null ||
    t1.minutes === null ||
    t2.hours === null ||
    t2.minutes === null
  ) {
    return null;
  }

  let hrs = t2.hours - t1.hours;
  let mins = t2.minutes - t1.minutes;

  if (mins < 0) {
    mins += 60;
    hrs -= 1;
  }

  if (hrs < 0) {
    hrs += 24;
  }

  if (hrs > 23) {
    hrs -= 24;
  }

  return hrs * 60 + mins;
}

export function timeDifferenceSigned(t1: Time, t2: Time): number | null {
  if (
    t1.hours === null ||
    t1.minutes === null ||
    t2.hours === null ||
    t2.minutes === null
  ) {
    return null;
  }

  let hrs = t2.hours - t1.hours;
  let mins = t2.minutes - t1.minutes;

  if (mins < 0) {
    mins += 60;
    hrs -= 1;
  }

  return hrs * 60 + mins;
}

export function isBetween(t: Time, tStart: Time, tEnd: Time) {
  const tMins = toMins(t);
  const tStartMins = toMins(tStart);
  let tEndMins = toMins(tEnd);

  if (tEndMins < tStartMins) {
    tEndMins += 24 * 60;
  }

  return tMins >= tStartMins && tMins <= tEndMins;
}

export function toMins(t: Time) {
  return t.hours * 60 + t.minutes;
}

export function getDaysInMonth(year: number, month: number): number {
  return [
    -1,
    31,
    isLeapYear(year) ? 29 : 28,
    31,
    30,
    31,
    30,
    31,
    31,
    30,
    31,
    30,
    31,
  ][month];
}

export function isLeapYear(year: number) {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
}

export function getDayofMonth(year: number, month: number, day: number = 1) {
  const d = new Date(year, month - 1, day).getDay();
  if (d === 0) return d + 7;
  return d;
}
