<div class="card mt-4">
  <h2>Competencies</h2>
  <div
    class="table border-separate overflow-x-scroll"
    style="border-spacing: 0.5rem"
  >
    <div class="table-row">
      <div class="table-cell"></div>
      <div
        class="table-cell font-semibold tracking-wide w-16 text-center"
        *ngFor="let record of records"
      >
        {{ record.date.toDate() | date: "dd MMM" }}
      </div>
    </div>
    <div
      class="table-row"
      *ngFor="let competency of course.competencies; let index = index"
    >
      <div class="table-cell font-semibold tracking-wide">
        {{ competency.longName }}
      </div>
      <div
        class="table-cell text-center px-2 rounded border cursor-pointer w-16"
        *ngFor="let record of records"
        [appDisplayColour]="record.gradedCompetencies[index].colours"
        (click)="openReport(record)"
      >
        <!-- [ngClass]="{
        'cursor-pointer': record.gradedCompetencies[index].behaviours.length
      }" -->

        {{ record.gradedCompetencies[index].shortResult
        }}{{
          record.gradedCompetencies[index].behaviours.length > 0
            ? " (" + record.gradedCompetencies[index].behaviours.length + ")"
            : ""
        }}
      </div>
    </div>
  </div>
</div>
