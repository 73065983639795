import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClubAdminHomeComponent } from './club-admin-home/club-admin-home.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { CoursesComponent } from './courses/courses.component';
import { CourseDetailsComponent } from './courses/course-details/course-details.component';
import { ClubMembersComponent } from './club-members/club-members.component';
import { ClubSettingsComponent } from './club-settings/club-settings.component';
import { CoursePickerDialogComponent } from './courses/course-picker-dialog/course-picker-dialog.component';
import { AddMemberDialogComponent } from './club-members/add-member-dialog/add-member-dialog.component';
import { ClubBookingsComponent } from './club-bookings/club-bookings.component';
import { CreateEventTypeDialogComponent } from './club-bookings/create-event-type-dialog/create-event-type-dialog.component';
import { CreateSlotDialogComponent } from './club-bookings/create-event-type-dialog/create-slot-dialog/create-slot-dialog.component';

@NgModule({
  declarations: [
    ClubAdminHomeComponent,
    CoursesComponent,
    CourseDetailsComponent,
    ClubMembersComponent,
    ClubSettingsComponent,
    CoursePickerDialogComponent,
    AddMemberDialogComponent,
    ClubBookingsComponent,
    CreateEventTypeDialogComponent,
    CreateSlotDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    ReactiveFormsModule
  ]
})
export class ClubAdminModule { }
