import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Role } from 'functions/src/models/user.model';
import { Subject } from 'rxjs';
import { MemberInfo } from 'src/app/models/club.model';
import { ClubService } from '../../club.service';

@Component({
  selector: 'app-pilot-lookup',
  templateUrl: './pilot-lookup.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => PilotLookupComponent),
    }
  ]
})
export class PilotLookupComponent implements ControlValueAccessor {
  public membershipNumberSearch = true
  public searchString = "";
  public state: "search" | "duplicate" | "result" = "search";
  public $duplicateMembers: Subject<MemberInfo[] | null> = new Subject();
  public lookupError: string | null = null;
  public value?: MemberInfo;
  private userID?: string;

  @Input() label: string = "Search:";
  @Input() minRole: Role = Role.pilot;

  constructor(public clubService: ClubService) { }

  writeValue(value: MemberInfo) {
    if (value && value.userID) {
      this.value = value;
      this.userID = value.userID;
      this.state = "result";
    } else {
      this.value = undefined;
      this.state = "search";
    }
  }

  propagateChange = (_: any) => { };
  registerOnChange(fn: (_: any) => void) {
    this.propagateChange = fn;
  }

  onTouched = () => { };
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

  async onFindMember(searchString: string) {
    //TODO: Check if search string is valid
    if (searchString) {
      this.state = "duplicate";

      const memberResults = await this.clubService
        .findMemberBy(this.membershipNumberSearch ? "membershipNo" : "lastName",
          this.membershipNumberSearch ? searchString.toUpperCase() : searchString.toLowerCase());

      if (memberResults.length === 0) {
        this.lookupError = "No pilot found, please try again.";
        this.state = "search";
      } else if (memberResults.length > 1) {
        //If >1 - Resolve obserable
        this.lookupError = "";
        this.$duplicateMembers.next(memberResults);
      } else {
        //If 1 - Goto next
        this.lookupError = "";
        this.onSelectPilot(memberResults[0]);
      }
    }
    else {
      this.lookupError = "Please enter a Membership No or Surname"
      return;
    }
  }

  onSelectPilot(pilot: MemberInfo) {
    this.state = 'result';
    this.value = pilot;
    this.userID = pilot.userID;
    this.propagateChange(pilot);
  }

  onCancel() {
    this.value = undefined;
    this.userID = undefined;
    this.propagateChange(undefined);
    this.state = "search";
  }
}
