<main class="grid grid-cols-1 md:grid-cols-4 gap-4">
  <button class="btn-blue md:hidden w-full max-w-screen-md mx-auto">
    <svg-icon key="calendar" class="mr-2"></svg-icon>
    View Calendar
  </button>
  <div class="card self-start">
    <h2>My Bookings</h2>
  </div>
  <div class="card hidden md:block md:col-span-3 self-start">
    <h2>Calendar</h2>
  </div>
</main>
