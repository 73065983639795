import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-calendar-event',
  templateUrl: './calendar-event.component.html',
})
export class CalendarEventComponent implements OnInit {
  @Input() calendarItem!: any;

  constructor() { }

  ngOnInit(): void {
  }

  onClick() { }
}
