import { Directionality } from '@angular/cdk/bidi';
import { CdkStepper } from '@angular/cdk/stepper';
import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Inject, Input } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  providers: [{ provide: CdkStepper, useExisting: StepperComponent }],
})
export class StepperComponent extends CdkStepper {

  @Input() hideNavigation: boolean = false;

  constructor(dir: Directionality,
    changeDetectorRef: ChangeDetectorRef,
    elementRef: ElementRef,
    // @Inject(DOCUMENT) private document: Document
  ) {
    super(dir, changeDetectorRef, elementRef);
  }
}
