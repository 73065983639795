<p class="mt-4">
  <span class="font-semibold tracking-wide">
    {{ competency.longName
    }}<span class="hidden md:inline"> ({{ competency.shortName }})</span>
  </span>
  <span
    [appDisplayColour]="competency.colours"
    class="rounded-md px-2 py-1 ml-2 border"
    >{{ competency.longResult }}</span
  >
</p>

<p class="text-sm mb-0 ml-4 mt-1" *ngIf="competency.addBehaviours === 1">
  {{ clubService.clubOptions?.positiveBehavioursText }}
</p>
<p class="text-sm mb-0 ml-4 mt-1" *ngIf="competency.addBehaviours === -1">
  {{ clubService.clubOptions?.negativeBehavioursText }}
</p>
<ul
  class="list-disc ml-12 text-sm italic mt-0.5"
  *ngIf="competency.behaviours.length"
>
  <li *ngFor="let behaviour of competency.behaviours">
    {{ behaviour }}
  </li>
</ul>
