import { Injectable, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AuthService } from './auth.service';
import { map, switchMap } from 'rxjs/operators';
import { Observable, Subscription, of } from 'rxjs';
import { Club, ClubOptions, MemberInfo } from '../models/club.model';
import { Role } from 'functions/src/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class ClubService implements OnDestroy {

  public $clubMembers: Observable<MemberInfo[] | undefined>;
  public $club: Observable<Club | undefined>;
  public $getClubs: Observable<Club[] | undefined>;
  public clubOptions: ClubOptions | null = null;
  private optionsSubscription: Subscription;

  constructor(private fs: AngularFirestore, private authService: AuthService) {
    this.$clubMembers = this.authService.$userDoc.pipe(switchMap((userDoc) => {
      if (userDoc === undefined) return of(undefined);
      if (!userDoc.selectedClubIndex && userDoc.selectedClubIndex !== 0) return of(undefined);
      return this.fs.collection<MemberInfo>(`clubs/${userDoc.clubs[userDoc.selectedClubIndex].clubID}/members`,
        ref => ref.limit(10).orderBy("lastName").orderBy("firstName")) //TODO: Paginate Limit
        .valueChanges()
    }));

    // TODO: Order by?
    this.$club = this.authService.$userDoc.pipe(switchMap((userDoc) => {
      if (userDoc === undefined) return of(undefined);
      if (!userDoc.selectedClubIndex && userDoc.selectedClubIndex !== 0) return of(undefined);
      return this.fs.doc<Club>(`clubs/${userDoc.clubs[userDoc.selectedClubIndex].clubID}`).valueChanges({ idField: "clubID" })
    }));

    this.optionsSubscription = this.$club.subscribe(club => {
      if (club) {
        //TODO: Doesn't update if contents does
        this.clubOptions = club.options;
      }
    })

    this.$getClubs = this.fs.collection<Club>(`clubs`).valueChanges({ idField: "clubID" });
  }

  findMemberBy(field: string, value: string): Promise<any> {

    // TODO: This errors when searching by surname because if cannot sort by surname

    return this.fs.collection<MemberInfo>(`clubs/${this.authService.user?.selectedClub?.clubID}/members`,
      ref => ref.where(field, "==", value).orderBy("lastName").orderBy("firstName")).get().pipe(map(results => {
        return results.docs.map(doc => {
          const data = doc.data();
          data.userID = doc.id;
          return data;
        })
      })).toPromise();
  };

  findMemberByID(id: string) {
    return this.fs.doc<MemberInfo>(`clubs/${this.authService.user?.selectedClub?.clubID}/members/${id}`)
      .get().pipe(map(doc => {
        const data = doc.data();
        if (data) {
          data.userID = doc.id;
        }
        return data;
      }))
      .toPromise();
  }

  ngOnDestroy(): void {
    this.optionsSubscription.unsubscribe();
  }
}
