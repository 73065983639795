import { Component } from '@angular/core';
import { fadeAnimation } from 'src/app/core/animations';
import { AuthService } from 'src/app/core/auth.service';
import { DialogService } from 'src/app/core/dialog/dialog.service';
import { ClubDetails, Role } from 'src/app/models/user.model';
import { ChangeClubDialogComponent } from './change-club-dialog/change-club-dialog.component';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  animations: [fadeAnimation]
})
export class SidebarComponent {
  Role = Role;
  public selectedClub: ClubDetails | null = null;
  public menu: { name: string, link: string, icon: string, options?: any }[] = [
    { name: "Dashboard", link: "", icon: "dashboard", options: { exact: true } },
    { name: "My Logbook", link: "/my-logbook", icon: "book" },
    { name: "My Training", link: "/my-training", icon: "record" },
    { name: "Flight Log", link: "/flying-log", icon: "takeoff" },
     { name: "Bookings", link: "/bookings", icon: "calendar" },
    { name: "New Training Record", link: "/new-training-record", icon: "add-record" },
    { name: "Search Training Records", link: "/search-records", icon: "search-record" },
    { name: "Settings", link: "/settings", icon: "settings" },
    { name: "Club Admin", link: "/club-admin", icon: "club-admin" },
    { name: "App Admin", link: "/app-admin", icon: "app-admin" },
  ]

  constructor(public authService: AuthService, private dialogService: DialogService) {

  }

  onChangeClub() {
    this.dialogService.open(ChangeClubDialogComponent, { data: this.authService.user?.clubs }).afterClosed.subscribe(async club => {
      if (club || club === 0) {
        await this.authService.setClubIndex(club);
      }
    })
  }

  filterCallback(item: any) {
    return true;
  }

  onLogout() {
    this.authService.signOut();
  }
}
