import { Component, Input, OnInit } from '@angular/core';
import { gradedTask, TrainingReport } from 'src/app/models/training-report.model';
import { Timestamp } from '@firebase/firestore-types';
import { Course } from 'src/app/models/course.model';

@Component({
  selector: 'app-course-summary-task-list',
  templateUrl: './course-summary-task-list.component.html'
})
export class CourseSummaryTaskListComponent {
  public searchValue: "all" | "notStarted" | "inProgress" | "completed" | "notCompleted" = 'all';

  @Input() tasks!: {
    [key: string]:
    {
      name: string,
      subTaskName?: string,
      events: { date: Timestamp, details: gradedTask, report: TrainingReport }[],
    }
  };

  constructor() { }

  returnZero() {
    return 0;
  }

  changeFilter(val: string) {
    if (val === "all" || val === "notStarted" || val === "inProgress" || val === "completed" || val === "notCompleted") {
      this.searchValue = val;
    } else {
      val = "all";
    }
  }

  isCompleted(task: { value: { name: string, events: { date: Timestamp, details: gradedTask }[] } }) {
    return task.value.events.length &&
      task.value.events[task.value.events.length - 1].details.completed;
  }

  filterCallback(item: { value: { name: string; events: { date: Timestamp; details: gradedTask; }[]; }; }) {
    switch (this.searchValue) {
      case "all":
        return true;
        break;
      case "completed":
        return this.isCompleted(item);
        break;
      case "inProgress":
        return !this.isCompleted(item) && item.value.events.length > 0;
        break;
      case "notStarted":
        return item.value.events.length === 0;
        break;
      case "notCompleted":
        return !this.isCompleted(item);
        break;
    }
  }

}
