<div class="card mt-4">
  <h2>Tasks</h2>
  <div class="flex w-full items-center my-2 text-sm">
    <p class="font-medium tracking-wide">Show:</p>
    <select
      class="ml-2"
      name="filter"
      #filter
      (change)="changeFilter(filter.value)"
    >
      <option value="all" selected>All</option>
      <option value="notStarted">Not Started</option>
      <option value="inProgress">In Progress</option>
      <option value="notCompleted">Not Completed</option>
      <option value="completed">Completed</option>
    </select>
  </div>

  <table class="overflow-x-scroll max-w-full">
    <ng-container *ngFor="let task of tasks | keyvalue: returnZero">
      <tr *ngIf="filterCallback(task)">
        <td class="text-center text-green-600 w-12">
          <svg-icon key="tick" *ngIf="isCompleted(task)"></svg-icon>
        </td>
        <td class="font-semibold tracking-wide mr-4">
          {{ task.value.name }}
          <span *ngIf="task.value.subTaskName">
            - {{ task.value.subTaskName }}
          </span>
        </td>
        <td class="overflow-x-visible">
          <app-course-summary-task-grade
            *ngFor="let event of task.value.events"
            [eventDetails]="event"
          ></app-course-summary-task-grade>
        </td>
      </tr>
    </ng-container>
  </table>
</div>
