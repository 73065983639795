import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppAdminHomeComponent } from './app-admin-home/app-admin-home.component';
import { RouterModule } from '@angular/router';
import { ClubsComponent } from './clubs/clubs.component';
import { CoreModule } from '../core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { AddClubDialogComponent } from './clubs/add-club-dialog/add-club-dialog.component';

@NgModule({
  declarations: [
    AppAdminHomeComponent,
    ClubsComponent,
    AddClubDialogComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    ReactiveFormsModule
  ],
  exports: []
})
export class AppAdminModule { }
