<form [formGroup]="form" class="flex flex-col">
  <h2>Create Event Type</h2>
  <p class="text-sm mb-4">
    Event Types are used as templates to create common events.
  </p>
  <div class="form-group">
    <label for="name">Name:</label>
    <input
      type="text"
      id="name"
      class=""
      formControlName="name"
      autocomplete="off"
    />
  </div>
  <div class="form-group">
    <label for="description">Description:</label>
    <textarea
      type="text"
      id="description"
      formControlName="description"
      autocomplete="off"
    ></textarea>
  </div>
  <div class="form-group">
    <label for="description">Colours:</label>
    <app-colour-selector
      formControlName="colour"
      label="Choose..."
      class="w-2/3"
    ></app-colour-selector>
  </div>
  <div class="form-group">
    <label for="defaultDuration">Default Duration (hours):</label>
    <input
      type="number"
      id="name"
      formControlName="defaultDuration"
      autocomplete="off"
    />
  </div>
  <h3 class="mt-4">Slot Types</h3>
  <p class="text-sm">
    Each event can have different types of slots that can be booked.
  </p>
  <button class="btn-green self-start mt-2" (click)="onAddSlot()">
    <svg-icon key="add" class="inline-block mr-1"></svg-icon>
    Add Slot
  </button>
  <ng-container formArrayName="slots">
    <div
      class="flex flex-row"
      *ngFor="let slot of slots.controls; let idx = index"
    ></div>
  </ng-container>
  <!-- export interface Slot {
    id: string,
    name: string,
    places: number | null,
    restriction: string[] | null,
    cost: number | null,
    approval: boolean,
} -->

  <div class="mt-4 flex justify-end">
    <button class="btn-outline btn-group-left" (click)="onCancel()">
      Cancel
    </button>
    <button class="btn-green btn-group-right" (click)="onSave()">OK</button>
  </div>
</form>
