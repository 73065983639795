import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DisplayColour } from 'src/app/models/display-colour.model';
import { DialogService } from '../dialog/dialog.service';
import { ColourSelectorDialogComponent } from './colour-selector-dialog/colour-selector-dialog.component';

@Component({
  selector: 'app-colour-selector',
  templateUrl: './colour-selector.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => ColourSelectorComponent),
  }]
})
export class ColourSelectorComponent implements ControlValueAccessor, OnInit {
  public value: DisplayColour = { fgColour: "#111827", bgColour: "transparent" };
  public disabled: boolean = false;
  public touched: boolean = false;
  @Input() public label!: string;

  constructor(private dialogService: DialogService) { }

  ngOnInit(): void {
    if (!this.label) {
      this.label = "Colours...";
    }
  }

  onClick() {
    this.onTouched()
    this.dialogService.open(ColourSelectorDialogComponent, { data: { ...this.value } }).afterClosed.subscribe(res => {
      this.value = res;
      this.onChange(res)
    });
  }

  writeValue(value: DisplayColour) {
    if (!value) value = { fgColour: "#111827", bgColour: "transparent" }
    if (!value.fgColour) value.fgColour = "#111827";
    if (!value.bgColour) value.bgColour = "transparent";
    this.value = value;
  }

  onChange = (_: any) => { };
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  onTouched = () => { };
  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(val: boolean) {
    //this.colourSelector.nativeElement.disabled = val;
  }
}
