<div
  class="my-4 -mt-2 text-center"
  *ngIf="authService.user as user; withLoading"
>
  <p class="text-lg text-gray-900 font-semibold tracking-wide cursor-default">
    {{ user.firstName | titlecase }} {{ user.lastName | titlecase }}
  </p>
  <p class="text-gray-700 cursor-default mt-2" *ngIf="user.clubs">
    {{ user.clubs[user.selectedClubIndex].clubName }}
    <span class="italic text-sm"
      >({{ Role[user.clubs[user.selectedClubIndex].role] | titlecase }})</span
    >
  </p>
  <a
    class="text-sm"
    *ngIf="user.clubs && user.clubs.length > 1"
    (click)="onChangeClub()"
  >
    Change Club
  </a>
  <button
    class="btn-blue-outline text-sm w-full justify-center mt-4"
    (click)="onLogout()"
  >
    <svg-icon key="logout" class="inline-block mr-2"></svg-icon
    ><span class="inline-block">Log Out</span>
  </button>
</div>

<ul>
  <li
    class="py-2 px-4 -mx-3 lg:-mx-4 cursor-pointer flex justify-between items-center hover:underline"
    [routerLink]="item.link"
    routerLinkActive="primary-gradient text-white"
    [routerLinkActiveOptions]="item.options || {}"
    *ngFor="let item of menu | callback:filterCallback"
  >
    <span>{{ item.name }}</span>
    <svg-icon [key]="item.icon"></svg-icon>
  </li>
</ul>
