<div
  class="rounded-2xl bg-gray-50 p-4 lg:p-8 max-w-md m-4 mx-auto lg:mt-12 drop-shadow-2xl"
  *ngIf="!hasCompleted; else completed"
>
  <h1>Forgotten Password</h1>
  <main class="flex-col items-stretch">
    <form (submit)="onSendRequest()">
      <div class="mt-2">
        <p>
          If you have forgotten your password, or you need to sign in for the
          first time, enter your email address below.
        </p>
        <input
          type="email"
          autocomplete="email"
          [formControl]="email"
          placeholder="email@address.com"
          class="w-full mt-2"
        />
        <p class="mt-2">
          To return to the login page
          <a routerLink="/login">click here</a>.
        </p>
      </div>
      <div *ngIf="hasError" class="text-sm mt-2 warning-message">
        Please ensure you have entered a valid email address!
      </div>
      <button
        class="btn-blue w-full mt-4 justify-center"
        type="button"
        (click)="onSendRequest()"
      >
        Request Password Reset
      </button>
    </form>
  </main>
</div>

<ng-template #completed>
  <div
    class="rounded-2xl bg-gray-50 p-4 lg:p-8 max-w-md m-4 mx-auto lg:mt-12 shadow-2xl drop-shadow-2xl"
  >
    <h1>Password Reset Sent</h1>
    <p>
      Thanks. If you have an account with this email address, a password reset
      link will be sent.
    </p>
  </div>
</ng-template>
