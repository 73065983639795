<div
  class="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-8 gap-2 items-center border-b border-gray-400 text-center py-4"
>
  <div class="align-middle">
    {{ flight.aircraft.reg }} ({{ flight.aircraft.type }})
  </div>
  <!-- <div>{{ flight.launchType }}</div> -->
  <button type="button" class="btn-blue-outline text-sm">More Info</button>
  <div class="">
    {{ flight.p1.firstName | titlecase }} {{ flight.p1.lastName | titlecase }}
  </div>
  <div class="">
    {{ flight.p2?.firstName | titlecase }} {{ flight.p2?.lastName | titlecase }}
  </div>
  <div *ngIf="flight.takeoff">
    <!--fixme: 28/7/22-->
    {{ flight.takeoff.hours || "0" }}:{{ flight.takeoff.minutes }}
  </div>
  <div *ngIf="!flight.takeoff">
    <app-time-selector
      label="Takeoff"
      iconKey="takeoff"
      (timeUpdated)="takeoff($event)"
    ></app-time-selector>
  </div>
  <div *ngIf="flight.landing">
    {{ flight.landing.hours }}:{{ flight.landing.minutes }}
  </div>
  <div *ngIf="!flight.landing">
    <app-time-selector
      label="Landing"
      iconKey="land"
      [disabled]="!flight.takeoff"
      (timeUpdated)="land($event)"
    ></app-time-selector>
  </div>
  <button
    type="button"
    *ngIf="flight.takeoff && flight.landing"
    class="text-sm btn-green col-span-2"
  >
    <svg-icon key="tick" class="inline-block mr-2"></svg-icon>
    Confirm
  </button>
</div>
