<table
  class="w-full mt-4"
  *ngIf="$dataItems | async as dataItems; else loading"
>
  <thead class="text-left">
    <tr>
      <th>Date</th>
    </tr>
  </thead>
  <tbody>
    <tr
      class="cursor-pointer hover:bg-gray-200"
      *ngFor="let item of dataItems"
    ></tr>
  </tbody>
</table>
<ng-template class="w-full" #loading>
  <app-loading></app-loading>
</ng-template>
