import { Time } from '@angular/common';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, of, switchMap } from 'rxjs';
import { Flight } from '../models/flight.model';
import { AuthService } from './auth.service';
import { CoreModule } from './core.module';

@Injectable({
    providedIn: CoreModule
})
export class LogService {

    public $openFlights: Observable<Flight[] | undefined>
    public $completedFlights: Observable<Flight[] | undefined>

    constructor(private fs: AngularFirestore, private authService: AuthService) {
        this.$openFlights = this.authService.$userDoc.pipe(switchMap((userDoc) => {
            if (userDoc === undefined) return of(undefined);
            return this.fs.collection<Flight>(`clubs/${userDoc.clubs[userDoc.selectedClubIndex].clubID}/openFlights`,
                ref => ref
                    .orderBy("date"))
                .valueChanges({ idField: 'flightID' })
        }));

        this.$completedFlights = this.authService.$userDoc.pipe(switchMap((userDoc) => {
            if (userDoc === undefined) return of(undefined);
            return this.fs.collection<Flight>(`clubs/${userDoc.clubs[userDoc.selectedClubIndex].clubID}/completedFlights`,
                ref => ref
                    .orderBy("date"))
                .valueChanges({ idField: 'flightID' })
        }));
    }

    addFlight(flight: Partial<Flight>) {
        // TODO Check Userdoc
        this.fs.collection(`clubs/${this.authService.user?.clubs[this.authService.user.selectedClubIndex].clubID}/openFlights`).doc().set({ ...flight, date: new Date() });
    }

    takeoff(flightID: string, time: Time) {
        this.fs.doc(`clubs/${this.authService.user?.clubs[this.authService.user.selectedClubIndex].clubID}/openFlights/${flightID}`).update({ takeoff: time });
    }

    land(flightID: string, time: Time) {
        this.fs.doc(`clubs/${this.authService.user?.clubs[this.authService.user.selectedClubIndex].clubID}/openFlights/${flightID}`).update({ landing: time });
    }

    finalize(flightID: string) {

    }
}