<div class="flex flex-row justify-end">
  <button class="btn-outline btn-group-left" (click)="onSubtractMonth()">
    Prev month
  </button>
  <button class="btn-outline btn-group-right" (click)="onAddMonth()">
    Next month
  </button>
</div>

<div class="grid grid-cols-7 gap-1 md:gap-3 mt-4 h-full">
  <div
    class="hidden md:block font-medium text-center"
    *ngFor="let day of ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']"
  >
    {{ day }}
  </div>
  <div
    class="md:hidden font-medium text-center"
    *ngFor="let day of ['M', 'T', 'W', 'T', 'F', 'S', 'S']"
  >
    {{ day }}
  </div>
  <div *ngIf="blanks >= 1"></div>
  <div *ngIf="blanks >= 2"></div>
  <div *ngIf="blanks >= 3"></div>
  <div *ngIf="blanks >= 4"></div>
  <div *ngIf="blanks >= 5"></div>
  <div *ngIf="blanks >= 6"></div>
  <div class="self-stretch" *ngFor="let item of daysOfMonth; let idx = index">
    <p class="text-center md:text-right font-light">{{ idx + 1 }}</p>
    <div class="hidden md:block rounded bg-gray-200 h-4 w-full"></div>
  </div>
</div>
