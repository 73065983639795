import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { CreateRecordComponent } from './create-record/create-record.component';
import { TrainingLookupComponent } from './training-lookup/training-lookup.component';
import { TrainingListComponent } from './training-list/training-list.component';
import { CompetencyComponent } from './training-list/training-report/competency/competency.component';
import { TaskComponent } from './training-list/training-report/task/task.component';
import { TrainingReportComponent } from './training-list/training-report/training-report.component';
import { CompetencyGradeSelectorComponent } from './create-record/competency-grade-selector/competency-grade-selector.component';
import { TaskGradeSelectorComponent } from './create-record/task-grade-selector/task-grade-selector.component';
import { CourseSummaryComponent } from './course-summary/course-summary.component';
import { CourseSummaryTaskGradeComponent } from './course-summary/course-summary-task-list/course-summary-grade/course-summary-task-grade.component';
import { CourseSummaryTaskListComponent } from './course-summary/course-summary-task-list/course-summary-task-list.component';
import { CourseSummaryCompetencyMapComponent } from './course-summary/course-summary-competency-map/course-summary-competency-map.component';

@NgModule({
  declarations: [
    CreateRecordComponent,
    CreateRecordComponent,
    TrainingLookupComponent,
    TrainingListComponent,
    TaskComponent,
    CompetencyComponent,
    TrainingReportComponent,
    CompetencyGradeSelectorComponent,
    TaskGradeSelectorComponent,
    CourseSummaryComponent,
    CourseSummaryTaskGradeComponent,
    CourseSummaryTaskListComponent,
    CourseSummaryCompetencyMapComponent,
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    CoreModule,
  ],
  exports: [
    TrainingListComponent,
    TaskComponent,
    CompetencyComponent,
    TrainingReportComponent,
    CompetencyGradeSelectorComponent,
    CourseSummaryTaskGradeComponent
  ]
})
export class TrainingModule { }
