import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { fromEventPattern } from 'rxjs';
import { ClubService } from 'src/app/core/club.service';
import { DialogRef } from 'src/app/core/dialog/dialog-ref';

@Component({
  selector: 'app-create-flight',
  templateUrl: './create-flight.component.html',
})
export class CreateFlightComponent {
  public form: FormGroup;
  public aircraftReg: FormControl;
  public aircraftType: FormControl;
  public aircraftList = true;

  constructor(private dialog: DialogRef,
    public clubService: ClubService) {
    this.form = new FormGroup({
      aircraft: new FormControl(null, [Validators.required]),
      launchType: new FormControl(null, [Validators.required]),
      p1: new FormControl(null, [Validators.required]),
      p2: new FormControl(null, [Validators.required]),
      comments: new FormControl(null),
      takeoff: new FormControl(null),
      landing: new FormControl(null),
    });

    this.aircraftReg = new FormControl(null, [Validators.required]);
    this.aircraftType = new FormControl(null, [Validators.required]);
  }

  onCancel() {
    this.dialog.close(null);
  }

  onAdd() {
    if (!this.aircraftList && this.aircraftReg.valid && this.aircraftType.valid) {
      this.form.controls.aircraft.setValue({
        reg: this.aircraftReg.value,
        type: this.aircraftType.value,
      })
    }
    if (this.form.valid) {
      this.dialog.close(this.form.value);
    }
  }

}
