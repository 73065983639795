import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Component({
  selector: 'app-select-list',
  templateUrl: './select-list.component.html',
})
export class SelectListComponent implements ControlValueAccessor {

  @Input() checkboxList!: string[]

  constructor() { }

  writeValue(value: string[]) {

  }

  propagateChange = (_: any) => { };
  registerOnChange(fn: (_: any) => void) {
    this.propagateChange = fn;
  }

  onTouched = () => { };
  registerOnTouched(fn: () => void) {
    this.onTouched = fn;
  }

}
