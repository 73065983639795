import { NgModule } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireFunctionsModule, REGION } from '@angular/fire/compat/functions';
import { BrowserModule } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { SidebarComponent } from './main/sidebar/sidebar.component';
import { LoginComponent } from './login/login.component';
import { ForgottenPasswordComponent } from './login/forgotten-password/forgotten-password.component';
import { EmailHandlerComponent } from './login/email-handler/email-handler.component';
import { ReactiveFormsModule } from '@angular/forms';
import { DashboardComponent } from './main/dashboard/dashboard.component';
import { AppRoutingModule } from './app-routing.module';
import { MainComponent } from './main/main.component';
import { CoreModule } from './core/core.module';
import { AppAdminModule } from './app-admin/app-admin.module';
import { ClubAdminModule } from './club-admin/club-admin.module';
import { ChangeClubDialogComponent } from './main/sidebar/change-club-dialog/change-club-dialog.component';
import { TrainingModule } from './training/training.module';
import { LogModule } from './log/log.module';
import { SettingsComponent } from './settings/settings.component';
import { ServiceWorkerModule } from '@angular/service-worker';

import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
import { BookingsModule } from './bookings/bookings.module';

@NgModule({
  declarations: [
    AppComponent,
    SidebarComponent,
    LoginComponent,
    DashboardComponent,
    MainComponent,
    SettingsComponent,
    ChangeClubDialogComponent,
    ForgottenPasswordComponent,
    EmailHandlerComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireFunctionsModule,
    AppRoutingModule,
    CoreModule,
    AppAdminModule,
    ClubAdminModule,
    TrainingModule,
    LogModule,
    BookingsModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !environment.useEmulators,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
  ],
  providers: [
    { provide: USE_AUTH_EMULATOR, useValue: environment.useEmulators ? ['http://localhost:9099'] : undefined },
    { provide: USE_FUNCTIONS_EMULATOR, useValue: environment.useEmulators ? ['localhost', 5001] : undefined },
    { provide: USE_FIRESTORE_EMULATOR, useValue: environment.useEmulators ? ['localhost', 8080] : undefined },
    { provide: REGION, useValue: "europe-west2" }
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }