<div class="card">
  <h2>Flying Log</h2>
  <div class="flex">
    <button
      class="btn-green w-full max-w-screen-md mx-auto"
      (click)="onAddFlight()"
    >
      <svg-icon key="takeoff" class="inline-block mr-2"></svg-icon>
      Add Flight
    </button>
  </div>
  <h2 class="mt-4">On Ground/Airborne</h2>
  <ng-container
    *ngIf="logService.$openFlights | async as flights; else loading"
  >
    <app-flight-list-entry
      *ngFor="let flight of flights"
      [flight]="flight"
      [editableID]="flight.flightID!"
    ></app-flight-list-entry>
  </ng-container>
  <h2 class="mt-4">Landed</h2>
  <app-flight-list-entry
    *ngFor="let flight of logService.$completedFlights | async"
    [flight]="flight"
  ></app-flight-list-entry>
</div>

<ng-template class="w-full mx-auto text-center text-2xl" #loading>
  <svg-icon key="loading-spinner" size="xxl"></svg-icon>
</ng-template>
