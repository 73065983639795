<form [formGroup]="form">
  <h2>Add Slot Type</h2>
  <p class="text-sm mb-4">
    Event Types are used as templates to create common events.
  </p>
  <div class="form-group">
    <label for="name">Name:</label>
    <input type="text" id="name" formControlName="name" autocomplete="off" />
  </div>
  <div class="form-group">
    <label for="maxPlaces"
      >Max Places <span class="text-sm">(zero for no limit):</span></label
    >
    <input
      type="number"
      id="maxPlaces"
      formControlName="maxPlaces"
      autocomplete="off"
    />
  </div>
  <div class="form-group">
    <label for="cost">Cost:</label>
    <input type="number" id="cost" formControlName="cost" autocomplete="off" />
  </div>
  <div class="checkbox-group">
    <input
      type="checkbox"
      id="publicBookings"
      formControlName="publicBookings"
    />
    <label for="publicBookings">Allow public bookings?</label>
  </div>
  <div class="checkbox-group">
    <input type="checkbox" id="adminApproval" formControlName="adminApproval" />
    <label for="adminApproval">Require admin approval?</label>
  </div>
  <div class="checkbox-group">
    <input
      type="checkbox"
      id="restrictBookings"
      formControlName="restrictBookings"
    />
    <label for="restrictBookings">Restrict bookings?</label>
  </div>
  <div class="form-group" *ngIf="showRestrictions">
    <label class="self-start">Training Stage Required</label>
    <app-select-list
      class="w-full md:w-2/3"
      [checkboxList]="trainingStages"
    ></app-select-list>
  </div>
  <div class="mt-4 flex justify-end">
    <button class="btn-outline btn-group-left" (click)="onCancel()">
      Cancel
    </button>
    <button class="btn-green btn-group-right" (click)="onSave()">OK</button>
  </div>
</form>
