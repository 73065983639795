<ng-container *ngIf="!loading">
  <h2 *ngIf="records.length === 0">
    {{ course.courseName }} - No Records Found
  </h2>
  <ng-container *ngIf="records.length > 0">
    <div class="card">
      <h2>{{ course.courseName }} Summary</h2>
      <p class="text-sm">
        <span class="font-semibold">Total Training Events:</span>
        {{ records.length }}
      </p>
      <p class="text-sm">
        <span class="font-semibold">First Event:</span>
        {{ records[0].date | userDate }}
      </p>
      <p class="text-sm">
        <span class="font-semibold">Last Event:</span>
        {{ records[records.length - 1].date | userDate }}
      </p>
    </div>
    <app-course-summary-task-list
      *ngIf="course.tasks.length > 0"
      [tasks]="taskList"
    ></app-course-summary-task-list>
    <app-course-summary-competency-map [records]="records" [course]="course">
    </app-course-summary-competency-map>
  </ng-container>
</ng-container>
<app-loading *ngIf="loading"></app-loading>
