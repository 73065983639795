import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../core/auth.service';

@Component({
  selector: 'app-email-handler',
  templateUrl: './email-handler.component.html',
})
export class EmailHandlerComponent {
  public mode: string;
  public code: string;
  public resetForm: FormGroup;
  public hasError: string | null = null;

  constructor(private activatedRoute: ActivatedRoute, private authService: AuthService, private router: Router) {
    this.mode = this.activatedRoute.snapshot.queryParams['mode'];
    this.code = this.activatedRoute.snapshot.queryParams['oobCode'];

    // TODO: Password strength requirement validators
    // TODO: Comparison by validator not if statement
    this.resetForm = new FormGroup({
      password: new FormControl(null, [Validators.required]),
      confirmPassword: new FormControl(null, [Validators.required])
    })
  }

  async onResetPassword() {
    this.hasError = null;

    const password = this.resetForm.controls.password.value;
    const confirmPassword = this.resetForm.controls.confirmPassword.value;

    if (password !== confirmPassword) {
      this.hasError = "Passwords do not match!";
    } else {
      try {
        await this.authService.resetPassword(this.code, password);
        this.router.navigate([""])
      } catch (error: any) {
        this.hasError = error.message;
      }
    }
  }

}
