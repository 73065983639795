<div class="inline-flex items-center">
    <input type="search" class="py-2 text-sm rounded-l-md focus:outline-none border px-2" placeholder="{{searchTitle}}" autocomplete="off" #search>
    <button class="inline-block border-t border-b px-2 self-stretch" [ngClass]="{'cursor-not-allowed text-gray-400': previousDisabled}" [disabled]=previousDisabled (click)=onPrev()>
        <svg-icon key="navigate-previous"></svg-icon> 
    </button>
    <button class="inline-block border-t border-b px-2 self-stretch" [ngClass]="{'cursor-not-allowed text-gray-400': nextDisabled}" [disabled]=nextDisabled  (click)=onNext()>
        <svg-icon key="navigate-next"></svg-icon> 
    </button>
    <select class="inline-block rounded-r-md border border-l-none focus:outline-none px-2 self-stretch">
        <option value="10">10</option>
        <option value="20" selected>20</option>
        <option value="50">50</option>
        <option value="100">100</option>
    </select>
</div>