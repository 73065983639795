<div class="flex items-center justify-between mt-4">
  <p>
    <span class="font-semibold tracking-wide">
      {{ task.name }}
    </span>
    <span class="font-normal tracking-normal" *ngIf="task.subTask">
      - {{ task.subTask.name }}</span
    >
    <span
      [appDisplayColour]="task.colours"
      class="rounded-md px-2 py-1 ml-2 border"
      >{{ task.longResult }}</span
    >
    <span
      *ngIf="task.completed"
      class="text-sm ml-2 text-green-600 align-bottom"
      ><svg-icon key="tick"></svg-icon
    ></span>
  </p>
  <button
    *ngIf="delete"
    tabindex="-1"
    class="icon-button flex-none self-stretch text-red-500"
    (click)="clickDelete()"
  >
    <svg-icon key="delete" class="mr-2"></svg-icon> Delete
  </button>
</div>
