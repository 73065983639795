import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
})
export class PaginatorComponent implements OnChanges {

  @Input()
  searchTitle: string = "Search...";

  @Output()
  previousClicked: EventEmitter<any> = new EventEmitter();

  @Output()
  nextClicked: EventEmitter<any> = new EventEmitter();

  @Output()
  searchValue: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  pageSize: EventEmitter<number> = new EventEmitter<number>();

  previousDisabled: boolean = true;
  nextDisabled: boolean = true;

  constructor() { }

  ngOnChanges() {

  }

  onPrev(): void {

  }

  onNext(): void {

  }
}
