import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-club-admin-home',
  templateUrl: './club-admin-home.component.html',
})
export class ClubAdminHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
