import { Component, Input, OnInit } from '@angular/core';
import { ClubService } from 'src/app/core/club.service';
import { gradedCompetency } from 'src/app/models/training-report.model';

@Component({
  selector: 'app-competency',
  templateUrl: './competency.component.html',
})
export class CompetencyComponent {

  @Input() competency!: gradedCompetency;

  constructor(public clubService: ClubService) { }

}
