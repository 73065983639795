<h2>Change Club</h2>
<div class="caution-message text-sm">
  <span class="font-semibold">Caution:</span> Changing clubs will discard any
  unsaved work.
</div>
<button
  class="btn w-full mt-2 font-medium"
  *ngFor="let club of config.data; let idx = index"
  (click)="onSelectClub(idx)"
>
  {{ club.clubName }}
  <span class="text-sm">: {{ Role[club.role] | titlecase }}</span>
</button>

<div class="flex items-baseline justify-end mt-4">
  <button class="btn" (click)="onCancel()">Cancel</button>
</div>
