import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClubService } from 'src/app/core/club.service';
import { DialogRef } from 'src/app/core/dialog/dialog-ref';
import { TrainingStage } from 'src/app/models/club.model';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-create-slot-dialog',
  templateUrl: './create-slot-dialog.component.html',
})
export class CreateSlotDialogComponent implements OnInit {
  public form: FormGroup;
  public trainingStages: string[] = [];

  constructor(private dialog: DialogRef, private clubService: ClubService) {
    this.form = new FormGroup({
      id: new FormControl(uuid()),
      name: new FormControl(null, [Validators.required]),
      maxPlaces: new FormControl(0, [Validators.required]),
      publicBookings: new FormControl(false),
      restrictBookings: new FormControl(false),
      restrictionList: new FormControl([]),
      adminApproval: new FormControl(false),
      cost: new FormControl(0),
    });
  }

  ngOnInit(): void {
    this.trainingStages = this.clubService.clubOptions?.trainingStages.map(x => x.name) || [];
  }

  onCancel() {
    this.dialog.close();
  }

  onSave() {
    if (this.form.valid) {
      this.dialog.close(this.form);
    }
  }

  get showRestrictions() {
    return this.form.controls.restrictBookings.value;
  }

}
