import { Timestamp } from '@firebase/firestore-types';
import { TrainingStage } from './club.model';
import { DisplayColour } from './display-colour.model';
import { Message } from './message.model';

export interface FireAuthUser {
  uid: string;
  email: string;
  photoURL?: string;
  displayName?: string;
  favoriteColor?: string;
}

export interface User {
  firstName: string,
  lastName: string,
  clubs: ClubDetails[],
  messages: Message[],
  selectedClubIndex: number,
  userID?: string,
  selectedClub?: ClubDetails,
  lastFlight?: Timestamp,
  lastTrainingEvent?: Timestamp,
  courses: EnrolledCourse[]
}

export interface EnrolledCourse { name: string, courseID: string, clubID: string, colour: DisplayColour, completed: boolean }

export interface ClubDetails {
  clubID: string,
  clubName: string,
  role: Role,
  memberID: string,
  joinedOn: Timestamp,
  trainingStage: TrainingStage,
}

export enum Role {
  "pilot" = 1,
  "instructor" = 5,
  "manager" = 10,
  "admin" = 20,
  "su" = 99,
}
