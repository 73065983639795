<div class="flex justify-between items-center">
  <h2>Training Report - {{ report.courseName }}</h2>
  <button class="btn-outline" (click)="onClose()">Close</button>
</div>
<div class="mt-4 columns-2 md:columns-4">
  <p class="font-semibold tracking-wide">Date</p>
  <p>{{ report.date | userDate }}</p>
  <p *ngIf="report.aircraft" class="font-semibold tracking-wide">AC Reg</p>
  <p *ngIf="report.aircraft">
    {{ report.aircraft.reg }} ({{ report.aircraft.type }})
  </p>
  <p *ngIf="report.instructorDetails" class="font-semibold tracking-wide">
    Instructor
  </p>
  <p *ngIf="report.instructorDetails">
    {{ report.instructorDetails.firstName | titlecase }}
    {{ report.instructorDetails.lastName | titlecase }}
  </p>
  <p *ngIf="report.clubName" class="font-semibold tracking-wide">Club</p>
  <p *ngIf="report.clubName">{{ report.clubName }}</p>
</div>

<ng-container *ngIf="report.gradedTasks.length > 0">
  <h2 class="mt-8">Tasks</h2>
  <app-task *ngFor="let task of report.gradedTasks" [task]="task"></app-task>
</ng-container>

<ng-container *ngIf="report.gradedCompetencies.length > 0">
  <h2 class="mt-8">Competencies</h2>
  <app-competency
    *ngFor="let competency of report.gradedCompetencies"
    [competency]="competency"
  ></app-competency>
</ng-container>

<h2 class="mt-8" *ngIf="report.narrative">Narrative</h2>
<p *ngIf="report.narrative">{{ report.narrative }}</p>

<h2 class="mt-8" *ngIf="report.comments.length || allowComment">Comments</h2>
<p *ngFor="let comment of report.comments">
  {{ comment.date | userDate }} - {{ comment.comment }}
</p>
<ng-container *ngIf="allowComment">
  <textarea class="w-full" rows="3" #newComment></textarea>
  <div class="flex justify-end">
    <button type="button" class="btn btn-green-outline">Save Comment</button>
  </div>
</ng-container>

<div class="flex justify-end mt-4">
  <button class="btn-outline" (click)="onClose()">Close</button>
</div>
