import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './dialog.component';
import { InsertionDirective } from './insertion.directive';
import { AlertComponent } from './alert/alert.component';

@NgModule({
  declarations: [DialogComponent, InsertionDirective, AlertComponent],
  imports: [CommonModule],
})
export class DialogModule {}
