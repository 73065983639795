import * as admin from "firebase-admin";
import Timestamp = admin.firestore.Timestamp;
import { DisplayColour } from "./display-colour.model";
import { TrainingStage } from "./club.model";
import { Message } from "./message.model";

export enum Role {
    "pilot" = 1,
    "instructor" = 5,
    "manager" = 10,
    "admin" = 20,
    "su" = 99,
}

export interface User {
    firstName: string,
    lastName: string,
    clubs: ClubDetails[],
    messages: Message[],
    selectedClubIndex: number,
    userID?: string,
    selectedClub?: ClubDetails,
    lastFlight?: Timestamp,
    lastTrainingEvent?: Timestamp,
    courses: EnrolledCourse[]
}

export interface EnrolledCourse { name: string, courseID: string, clubID: string, colour: DisplayColour, completed: boolean }

export interface ClubDetails {
    clubID: string,
    clubName: string,
    role: Role,
    memberID: string,
    joinedOn: Timestamp,
    trainingStage: TrainingStage,
}