<div class="card">
  <nav class="mb-2 flex justify-between items-center">
    <button class="btn-green" (click)="onAddClub()">
      <svg-icon key="add" class="inline-block mr-1"></svg-icon>
      Add Club
    </button>
    <app-paginator searchTitle="Club Name..."></app-paginator>
  </nav>
  <table
    class="table-auto w-full text-left cursor-default"
    *ngIf="clubService.$getClubs | async as clubs; else loading"
  >
    <thead>
      <tr class="">
        <th class="px-2">Club Name</th>
        <th class="px-2">Location</th>
        <th class="px-2">Members</th>
        <th class="px-2 hidden md:table-cell">Created</th>
      </tr>
    </thead>
    <tbody>
      <tr class="rounded-md" *ngFor="let club of clubs">
        <td class="px-2">{{ club.name }}</td>
        <td class="px-2">{{ club.location }}</td>
        <td class="px-2">{{ club.members }}</td>
        <td class="px-2 hidden md:table-cell">
          {{ club.created.toDate() | date: "dd MMM yy" }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template class="w-full" #loading>
  <app-loading></app-loading>
</ng-template>
