import { Component, Input, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, of, switchMap } from 'rxjs';
import { fadeAnimation } from 'src/app/core/animations';
import { AuthService } from 'src/app/core/auth.service';
import { DialogService } from 'src/app/core/dialog/dialog.service';
import { TrainingReport } from 'src/app/models/training-report.model';
import { EnrolledCourse, User } from 'src/app/models/user.model';
import { CourseSummaryComponent } from '../course-summary/course-summary.component';
import { TrainingReportComponent } from './training-report/training-report.component';

@Component({
  selector: 'app-training-list',
  templateUrl: './training-list.component.html',
  animations: [fadeAnimation]
})
export class TrainingListComponent implements OnInit {
  @Input() userID!: string;
  @Input() forSelf: boolean = true;

  public $reports: Observable<TrainingReport[] | null> | null = null;
  public $user: Observable<User | undefined> | null = null;

  constructor(private dialogService: DialogService, private fs: AngularFirestore, private authService: AuthService) {

  }

  ngOnInit(): void {
    if (this.forSelf) {
      this.userID = this.authService.user?.userID || "";
    }

    // TODO: Unsubscribe?
    this.$user = this.fs.doc<User>(`users/${this.userID}`).valueChanges();

    //TOOD: Get 1 years worth
    this.$reports = this.fs.collection<TrainingReport>(`users/${this.userID}/training`, ref => ref.limit(10).orderBy("date", "desc")).valueChanges();
  }

  onClickCourse(course: EnrolledCourse) {
    this.dialogService.open(CourseSummaryComponent, { data: { course: course, userID: this.userID } })
  }

  onClickReport(report: TrainingReport) {
    this.dialogService.open(TrainingReportComponent, { data: report })
  }

}
