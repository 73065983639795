<form
  [formGroup]="form"
  class="flex flex-col"
  *ngIf="clubService.$club | async as club; else loading"
  @fadeAnimation
>
  <h2>Add Member to Club</h2>
  <p class="text-sm">Give the details of the user to be added to the club.</p>
  <p class="text-sm">
    If they already have an account (member of another club), then the new club
    will be added to their account.
  </p>
  <p class="text-sm mb-4">
    If they do not have an account, an account will be created, and they will be
    sent an email invitation to set a password.
  </p>
  <div class="form-group">
    <label for="firstName">First Name:</label>
    <input
      type="text"
      id="firstName"
      formControlName="firstName"
      autocomplete="off"
    />
  </div>
  <div class="form-group">
    <label for="lastName">Last Name:</label>
    <input
      type="text"
      id="lastName"
      formControlName="lastName"
      autocomplete="off"
    />
  </div>
  <div class="form-group">
    <label for="email">Member Email:</label>
    <input type="text" id="email" formControlName="email" autocomplete="off" />
  </div>
  <div class="form-group">
    <label for="membershipNo">Membership No:</label>
    <input
      type="text"
      id="membershipNo"
      formControlName="membershipNo"
      autocomplete="off"
    />
  </div>
  <div class="form-group">
    <label for="trainingStage">Training Stage:</label>
    <select id="trainingStage" formControlName="trainingStage">
      <option
        *ngFor="let stage of club.options.trainingStages"
        [ngValue]="stage"
        [ngStyle]="{
          color: stage.colours.fgColour,
          backgroundColor: stage.colours.bgColour
        }"
      >
        {{ stage.name }}
      </option>
    </select>
  </div>

  <div class="form-group">
    <label for="role">Role:</label>
    <select id="role" formControlName="role">
      <option value="1" selected>Pilot</option>
      <option value="5">Instructor</option>
      <option value="10">Manager</option>
      <option value="20">Admin</option>
    </select>
  </div>
  <div class="mt-4 flex justify-end">
    <button class="btn-outline btn-group-left" (click)="onCancel()">
      Cancel
    </button>
    <button class="btn-green btn-group-right" (click)="onSave()">OK</button>
  </div>
</form>

<ng-template class="w-full" #loading>
  <app-loading></app-loading>
</ng-template>
