import { Component, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { fadeAnimation } from 'src/app/core/animations';
import { AuthService } from 'src/app/core/auth.service';
import { DialogService } from 'src/app/core/dialog/dialog.service';
import { Message, MessageAction } from 'src/app/models/message.model';
import { TrainingReportComponent } from 'src/app/training/training-list/training-report/training-report.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  animations: [fadeAnimation]
})

export class DashboardComponent implements OnDestroy {

  constructor(public authService: AuthService, private fs: AngularFirestore, private dialogService: DialogService) {

  }

  ngOnDestroy(): void {
    
  }

  async onMessageClick(message: Message): Promise<any> {
    switch (message.action) {
      case MessageAction.reportAcknowledge:
        
      break;
    }
  }
}
