import { Component, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { DialogRef } from 'src/app/core/dialog/dialog-ref';
import { DialogService } from 'src/app/core/dialog/dialog.service';
import { CreateSlotDialogComponent } from './create-slot-dialog/create-slot-dialog.component';
@Component({
  selector: 'app-create-event-type-dialog',
  templateUrl: './create-event-type-dialog.component.html',
})
export class CreateEventTypeDialogComponent {

  public form: FormGroup;

  constructor(
    private dialog: DialogRef,
    private dialogService: DialogService,
  ) {
    this.form = new FormGroup({
      name: new FormControl(null, [Validators.required]),
      description: new FormControl(null),
      colour: new FormControl(null),
      slots: new FormArray([]),
      defaultDuration: new FormControl(0, [Validators.required])
    })
  }

  get slots() {
    return <FormArray>this.form.controls.slots;
  }

  onAddSlot() {
    this.dialogService.open(CreateSlotDialogComponent, {}).afterClosed.subscribe(res => {
      if (res) {
        this.slots.push(res);
      }
    });
  }

  onCancel() {
    this.dialog.close();
  }

  async onSave() {
    if (this.form.valid) {
      try {

        this.dialog.close();
      } catch {

      }
    }
  }
}
