import { Time } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { LogService } from 'src/app/core/log.service';
import { Flight } from 'src/app/models/flight.model';

@Component({
  selector: 'app-flight-list-entry',
  templateUrl: './flight-list-entry.component.html',
})
export class FlightListEntryComponent {
  @Input() flight!: Flight;
  @Input() editableID: string | null = null;

  constructor(private logService: LogService) { }

  takeoff(val: Time) {
    if (this.editableID) {
      this.logService.takeoff(this.editableID, val);
    }
  }
  land(val: Time) {
    if (this.editableID) {
      this.logService.land(this.editableID, val);
    }
  }

}
