<div
  class="rounded-2xl bg-gray-50 p-4 lg:p-8 max-w-md m-4 mx-auto lg:mt-12 drop-shadow-2xl"
>
  <h1>Login</h1>
  <form (keyup.enter)="onLogin()">
    <main class="flex-col items-stretch">
      <input
        type="email"
        autocomplete="email"
        [formControl]="email"
        placeholder="email@address.com"
        class="w-full"
      />
      <input
        type="password"
        autocomplete="current-password"
        [formControl]="password"
        placeholder="********"
        class="w-full mt-2"
      />
      <div class="mt-2">
        If you have forgotten your password, or if your club has set an account
        up for you,
        <a routerLink="/forgotten-password">click here</a>.
      </div>
      <div *ngIf="hasError" class="text-sm mt-4 warning-message">
        {{ errorText }}
      </div>
      <button
        class="btn-blue w-full mt-4 justify-center"
        type="button"
        (click)="onLogin()"
      >
        Log In
      </button>
    </main>
  </form>
</div>
