<main class="card">
  <h2>
    {{ selected?.label }}
  </h2>
  <ng-container
    [ngTemplateOutlet]="selected ? selected.content : null"
  ></ng-container>
  <footer class="w-full flex justify-between mt-4" *ngIf="!hideNavigation">
    <button class="btn bg-white" cdkStepperPrevious>Previous</button>
    <button class="btn bg-white" cdkStepperNext>Next</button>
  </footer>
</main>
