<div
  class="z-40 overflow-hidden bg-smoke-light w-screen fixed h-full mt-16"
  *ngIf="sideNav"
  (click)="sideNav = !sideNav"
>
  <nav style="width: 240px" class="bg-gray-50 shadow p-3 h-full">
    <app-sidebar></app-sidebar>
  </nav>
</div>

<div class="h-full flex flex-row items-stretch p-4">
  <header
    class="flex-none h-16 p-3 shadow-inner primary-gradient fixed top-0 w-full flex justify-between -mx-3 lg:-mx-4"
    *ngIf="!largeNav"
  >
    <div class="text-white text-lg flex items-center">
      <svg-icon key="flyable" class="mr-1.5" size="lg"></svg-icon>
      <span class="font-semibold tracking-wider">flyable</span
      ><span class="font-thin tracking-wide">.aero</span>
    </div>
    <button
      class="focus:border-white focus:outline-none text-white"
      (click)="sideNav = !sideNav"
    >
      <svg-icon key="menu" size="lg" class="inline-block mr-2"></svg-icon>
    </button>
  </header>
  <div
    class="flex-1 flex max-w-screen-xxl mx-auto items-stretch"
    [ngClass]="{ 'mt-16': !largeNav }"
  >
    <nav
      style="width: 240px"
      class="card rounded-lg self-start mr-4"
      *ngIf="largeNav"
    >
      <div
        class="text-white text-lg -m-4 rounded-t-lg h-16 p-3 mb-4 primary-gradient flex items-center"
      >
        <svg-icon key="flyable" class="mr-1.5" size="lg"></svg-icon>
        <span class="font-semibold tracking-wider">flyable</span
        ><span class="font-thin tracking-wide">.aero</span>
      </div>
      <app-sidebar></app-sidebar>
    </nav>
    <main class="flex-1">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>
