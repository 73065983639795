<div
  class="card"
  *ngIf="
    trainingService.$clubCoursesWithHidden | async as courses;
    else loading
  "
  @fadeAnimation
>
  <nav class="mb-2 flex justify-between items-center">
    <button class="btn-green" [routerLink]="['create']">
      <svg-icon key="add" class="inline-block mr-1"></svg-icon>
      Create Training Course
    </button>
  </nav>
  <table class="table-fixed w-full cursor-default mt-4">
    <thead>
      <tr>
        <th class="px-2">Edit</th>
        <th class="px-2">Course</th>
        <th class="px-2">Last Updated</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="
          let course of courses;
          let firstCourse = first;
          let lastCourse = last
        "
      >
        <td class="text-center">
          <button
            class="btn-outline"
            [routerLink]="['details']"
            [state]="{ course: course }"
          >
            <svg-icon class="mr-2" key="edit"></svg-icon> Edit Course
          </button>
        </td>
        <td
          class="px-2 py-1 rounded-lg"
          [ngStyle]="{
            color: course.colours.fgColour,
            backgroundColor: course.colours.bgColour
          }"
        >
          {{ course.courseName }}
        </td>
        <td class="inline-flex align-middle">
          <button
            class="icon-button"
            [ngClass]="{ 'text-gray-400': firstCourse }"
          >
            <svg-icon key="arrow-up"></svg-icon>
          </button>
          <button
            class="icon-button"
            [ngClass]="{ 'text-gray-400': lastCourse }"
          >
            <svg-icon key="arrow-down"></svg-icon>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template class="w-full" #loading>
  <app-loading></app-loading>
</ng-template>
