import { Component, Input, OnInit } from '@angular/core';
import { DialogRef } from 'src/app/core/dialog/dialog-ref';
import { DialogConfig } from 'src/app/core/dialog/dialog.config';
import { TrainingReport } from 'src/app/models/training-report.model';

@Component({
  selector: 'app-training-report',
  templateUrl: './training-report.component.html',
})
export class TrainingReportComponent {

  @Input()
  public report!: TrainingReport;

  @Input()
  public allowComment: boolean = false;

  constructor(private config: DialogConfig, private dialog: DialogRef) {
    this.report = this.report || this.config.data;
  }

  onClose() {
    this.dialog.close();
  }

  onAddComment() {

  }

}
