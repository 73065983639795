import { animation, style, animate, transition, trigger } from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
    transition(':enter', [
        style({ opacity: 0 }), animate('300ms', style({ opacity: 1 }))]
    ),
    transition(':leave',
        [style({ opacity: 1 }), animate('300ms', style({ opacity: 0 }))]
    )
]);
export const quickFadeAnimation = trigger('quickFadeAnimation', [
    transition(':enter', [
        style({ opacity: 0 }), animate('100ms', style({ opacity: 1 }))]
    ),
    transition(':leave',
        [style({ opacity: 1 }), animate('100ms', style({ opacity: 0 }))]
    )
]);