import { Component, OnInit } from '@angular/core';
import { fadeAnimation } from 'src/app/core/animations';
import { DialogService } from 'src/app/core/dialog/dialog.service';
import { Role } from 'src/app/models/user.model';
import { ClubService } from '../../core/club.service';
import { AddMemberDialogComponent } from './add-member-dialog/add-member-dialog.component';

@Component({
  selector: 'app-club-members',
  templateUrl: './club-members.component.html',
  animations: [fadeAnimation]
})
export class ClubMembersComponent implements OnInit {
  Role = Role;

  constructor(public clubService: ClubService, private dialogService: DialogService) { }

  ngOnInit(): void {
  }

  onAddMember() {
    this.dialogService.open(AddMemberDialogComponent, {}).afterClosed.subscribe((res) => {
      //TODO: Something here!
    });
  }

}
