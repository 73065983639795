import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { BookingsHomeComponent } from './bookings-home/bookings-home.component';

@NgModule({
  declarations: [
    BookingsHomeComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    CoreModule,
  ],
})
export class BookingsModule { }
