import { Component } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { fadeAnimation } from 'src/app/core/animations';
import { ClubService } from 'src/app/core/club.service';
import { DisplayColour } from 'src/app/models/display-colour.model';

@Component({
  selector: 'app-club-settings',
  templateUrl: './club-settings.component.html',
  animations: [fadeAnimation]
})
export class ClubSettingsComponent {

  public form: FormGroup;
  public clubSubscription: Subscription;
  public loaded = false;
  clubID?: string;


  constructor(private clubService: ClubService, private fs: AngularFirestore, private router: Router) {
    this.form = new FormGroup({
      enableLog: new FormControl(null),
      enablePilotCreatedReport: new FormControl(null),
      aircraft: new FormArray([]),
      trainingStages: new FormArray([]),
      positiveBehavioursText: new FormControl(null),
      negativeBehavioursText: new FormControl(null),
      minimumBehaviours: new FormControl(null),
      maximumBehaviours: new FormControl(null)
    });

    this.clubSubscription = clubService.$club.subscribe(club => {
      this.form.reset();
      this.aircraft.reset();
      this.trainingStages.reset();
      if (club) {
        this.loaded = true;
        this.form.patchValue(club.options);
        this.clubID = club.clubID;
        club.options.trainingStages.forEach(ts => {
          this.onAddTrainingStage(ts.name, ts.colours);
        });
        club.options.aircraft.forEach(ac => {
          this.onAddAircraft(ac.reg, ac.type);
        })
      } else {
        this.loaded = false;
      }
    });
  }

  onUpdateSettings() {
    if (this.form.valid) {
      this.fs.collection("clubs").doc(this.clubID).update({ options: this.form.value });
      this.router.navigate(["club-admin"])
    }
    // TODO: If not valid!
  }

  get aircraft() {
    return <FormArray>this.form.get("aircraft");
  }
  get trainingStages() {
    return <FormArray>this.form.get("trainingStages");
  }

  onAddAircraft(reg: string = "", type: string = "") {
    this.aircraft.push(new FormGroup({
      reg: new FormControl(reg, [Validators.required]),
      type: new FormControl(type, [Validators.required])
    }));
  }

  onRemoveAircraft(idx: number) {
    this.aircraft.removeAt(idx);
  }

  onAddTrainingStage(name: string = "", colours?: DisplayColour) {
    this.trainingStages.push(new FormGroup({
      name: new FormControl(name, [Validators.required]),
      colours: new FormControl({
        fgColour: colours?.fgColour || null,
        bgColour: colours?.bgColour || null,
      })
    }));
  }

  onRemoveTrainingStage(idx: number) {
    if (this.trainingStages.length > 1) {
      this.trainingStages.removeAt(idx);
    }
  }

}
