import { Component } from '@angular/core';
import { DialogRef } from 'src/app/core/dialog/dialog-ref';
import { TrainingService } from 'src/app/core/training.service';
import { Course } from 'src/app/models/course.model';

@Component({
  selector: 'app-course-picker-dialog',
  templateUrl: './course-picker-dialog.component.html',
})
export class CoursePickerDialogComponent {

  constructor(private dialog: DialogRef, public trainingService: TrainingService,) { }

  chooseCourse(course: Course) {
    this.dialog.close(course);
  }

  onCancel() {
    this.dialog.close(null);
  }

}
