import { Timestamp } from '@firebase/firestore-types';

export interface Message {
    action: MessageAction,
    title: string,
    message: string,
    date: Timestamp,
    colour: string
    messageID?: string |
    reportMessage
}

interface reportMessage {
    action: MessageAction.reportAcknowledge | MessageAction.reportReview | MessageAction.reportVerify,
    actionData?: string | { uid: string, record: string },
}

export enum MessageAction {
    "acknowledge" = 1,
    "reportAcknowledge" = 100,
    "reportVerify" = 101,
    "reportReview" = 102,
    "initialUserSetup" = 201,
    "initialClubSetup" = 202,
}