<ng-container *ngIf="step == 'tasks'">
  <h2>Add a Task</h2>
  <button
    class="btn border w-full mt-2 font-medium text-center"
    *ngFor="let item of config.data.tasks; let idx = index"
    (click)="selectTask(idx)"
  >
    {{ item.name }}
  </button>
</ng-container>

<ng-container *ngIf="step == 'subtasks'">
  <button
    type="button"
    class="btn border inline-block text-sm mr-2"
    (click)="step = 'tasks'"
  >
    <svg-icon key="previous" class="inline-block mr-2"></svg-icon>
    Go back
  </button>
  <h2 class="inline-block">Select Sub-Task</h2>
  <button
    class="btn-outline w-full mt-2 text-center"
    *ngFor="let item of subTasks; let idx = index"
    (click)="selectSubtask(idx)"
  >
    {{ item.name }}
  </button>
</ng-container>

<ng-container *ngIf="step == 'grades'">
  <button
    type="button"
    class="btn inline-block text-sm mr-2"
    (click)="step = subTasks.length ? 'subtasks' : 'tasks'"
  >
    <svg-icon key="previous" class="inline-block mr-2"></svg-icon>
    Go back
  </button>
  <h2 class="inline-block">
    Select Grade for {{ config.data.tasks[selectedTaskIndex].name }}
  </h2>
  <p>
    This task will be marked as completed (<span
      class="text-sm text-green-600 align-bottom"
      ><svg-icon key="tick"></svg-icon></span
    >) if the last grade in the list is selected.
  </p>
  <button
    class="btn border text-center w-full mt-2"
    *ngFor="let grade of config.data.taskGrades; let idx = index"
    (click)="selectGrade(idx)"
    [appDisplayColour]="grade.colours"
  >
    {{ grade.longResult }}
  </button>
</ng-container>
