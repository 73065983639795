<div [formGroup]="competencyGradeForm">
  <button
    type="button"
    *ngFor="let grade of competencyGrades; let gradeIndex = index"
    class="btn border w-full mt-2"
    [appDisplayColour]="
      selectedGradeIndex === gradeIndex
        ? grade.colours
        : { fgColour: '', bgColour: '' }
    "
    [ngClass]="{
      'font-semibold tracking-wide': selectedGradeIndex === gradeIndex
    }"
    (click)="onClickGrade(grade, gradeIndex)"
  >
    <svg-icon
      key="tick"
      class="inline-block mr-2"
      *ngIf="selectedGradeIndex === gradeIndex"
    ></svg-icon>
    {{ grade.longResult }}
  </button>
  <div
    class="flex justify-between items-stretch mt-4"
    *ngIf="showBehaviours !== 0"
  >
    <label class="mr-4 self-end" *ngIf="showBehaviours === 1">
      {{ clubService.clubOptions?.positiveBehavioursText }}
    </label>
    <label class="mr-4 self-end" *ngIf="showBehaviours === -1">
      {{ clubService.clubOptions?.negativeBehavioursText }}
    </label>
    <button
      type="button"
      class="btn-blue-outline text-sm"
      (click)="onAddCompetencyBehaviour()"
      *ngIf="showBehaviours !== 0"
    >
      <svg-icon key="add" class="inline-block mr-2"></svg-icon
      ><span class="inline-block">Add Behaviour</span>
    </button>
  </div>
  <div
    class="mt-2 flex items-center"
    *ngFor="let item of behaviours.controls; let idx = index"
    formArrayName="behaviours"
  >
    <select class="w-full flex-1 mr-2" [formControlName]="idx">
      <option [ngValue]="null"></option>
      <option
        [ngValue]="behaviour"
        *ngFor="let behaviour of competency?.behaviours"
      >
        {{ behaviour }}
      </option>
    </select>
    <button
      tabindex="-1"
      class="icon-button flex-none self-stretch text-red-500"
      (click)="onRemoveCompetencyBehaviour(idx)"
    >
      <svg-icon key="delete"></svg-icon>
    </button>
  </div>
</div>
