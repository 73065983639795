import { Time } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-time-selector',
  templateUrl: './time-selector.component.html',
})
export class TimeSelectorComponent {

  @Input() disabled = false;
  @Input() label = "";
  @Input() iconKey = "";

  @Output() timeUpdated = new EventEmitter<Time>();

  // TODO: Validator
  public timeInput: FormControl = new FormControl(null);

  public buttonVisible = true;

  constructor() { }

  onButtonClick() {
    this.buttonVisible = false;
    this.timeInput.setValue(`${new Date().getHours()}:${(new Date().getMinutes() < 10 ? '0' : '') + new Date().getMinutes()}`);
  }

  onOKClick() {
    if (this.timeInput.valid) {
      this.timeInput.disable();
      const [hours, minutes] = (this.timeInput.value as string).split(":").map(x => parseInt(x));
      this.timeUpdated.emit({ hours, minutes });
    }
  }
}
