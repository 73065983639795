import { Component, OnInit } from '@angular/core';
import { DialogRef } from 'src/app/core/dialog/dialog-ref';
import { DialogConfig } from 'src/app/core/dialog/dialog.config';
import { Role } from 'src/app/models/user.model';

@Component({
  selector: 'app-change-club-dialog',
  templateUrl: './change-club-dialog.component.html',
})
export class ChangeClubDialogComponent implements OnInit {

  Role = Role

  constructor(public config: DialogConfig, private dialog: DialogRef) { }

  ngOnInit(): void {
  }

  onSelectClub(idx: number) {
    this.dialog.close(idx);
  }

  onCancel() {
    this.dialog.close(null);
  }

}
