import { Injectable, OnDestroy } from '@angular/core';
import { CoreModule } from './core.module';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { Message } from '../models/message.model';
import { User } from '../models/user.model';


@Injectable({
  providedIn: CoreModule
})
export class AuthService implements OnDestroy {
  public $userDoc: Observable<User | undefined>;
  public $messages: Observable<Message[] | undefined>;
  public user: User | null = null;
  public userSub: Subscription;

  constructor(private auth: AngularFireAuth,
    private fs: AngularFirestore,
    private router: Router) {

    this.$userDoc = this.auth.authState.pipe(switchMap(user => {
      if (user === null || user === undefined) return of(undefined);
      return this.fs.doc<User>(`users/${user.uid}`).valueChanges({ idField: "userID" });
    }));

    this.userSub = this.$userDoc.subscribe(userDoc => {
      if (!userDoc || !userDoc.clubs) {
        this.user = null;
      } else {
        this.user = userDoc;
        this.user.selectedClub = this.user.clubs[this.user.selectedClubIndex || 0];
      }
    });

    this.$messages = this.auth.authState.pipe(switchMap(user => {
      if (user === null || user === undefined) return of(undefined);
      return this.fs.collection<Message>(`users/${user.uid}/messages`,
        ref => ref.orderBy("date", "desc").limit(10)).valueChanges({ idField: "messageID" });
    }));
  }

  public signInWithEmailAndPassword(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  get isLoggedIn(): boolean {
    return this.auth.authState !== null;
  }

  public async setClubIndex(idx: number) {
    if (idx !== this.user?.selectedClubIndex) {
      await this.fs.doc(`users/${this.user?.userID}`).update({ selectedClubIndex: idx });
      // TODO: Error catching on this
      this.router.navigate([""]);
    }
  }

  public async resetPasswordRequest(email: string): Promise<void> {
    return this.auth.sendPasswordResetEmail(email);
  }

  public async resetPassword(oobCode: string, newPassword: string): Promise<any> {
    const email = await this.auth.verifyPasswordResetCode(oobCode);
    await this.auth.confirmPasswordReset(oobCode, newPassword);
    return this.auth.signInWithEmailAndPassword(email, newPassword);
  }

  public signOut() {
    this.router.navigate(["/login"]);
    return this.auth.signOut();
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }
}