<h2>Add Flight</h2>
<form [formGroup]="form">
  <div class="flex items-baseline mt-2">
    <label for="aircraft" class="mr-2 font-semibold tracking-wide"
      >Aircraft:</label
    >
    <select class="flex-1 mr-2" formControlName="aircraft" *ngIf="aircraftList">
      <option [ngValue]="null"></option>
      <option
        [ngValue]="aircraft"
        *ngFor="let aircraft of clubService.clubOptions?.aircraft"
      >
        {{ aircraft.reg }} ({{ aircraft.type }})
      </option>
    </select>
    <input
      [formControl]="aircraftReg"
      type="text"
      class="mr-2 w-1/2"
      placeholder="Reg."
      *ngIf="!aircraftList"
    />
    <input
      [formControl]="aircraftType"
      type="text"
      class="mr-2 w-1/2"
      placeholder="Type"
      *ngIf="!aircraftList"
    />
    <button
      class="btn-blue-outline flex-none text-sm"
      type="button"
      (click)="aircraftList = !aircraftList"
    >
      {{ aircraftList ? "Another Aircraft" : "Choose from List" }}
    </button>
  </div>
  <div class="flex items-baseline mt-2">
    <label for="launchType" class="mr-2 font-semibold tracking-wide"
      >Launch Type:</label
    >
    <select class="flex-1" formControlName="launchType">
      <option selected>Aerotow</option>
      <option>Winch</option>
      <option>Bungee</option>
      <option>Car</option>
      <option>Self</option>
    </select>
  </div>
  <div class="flex items-baseline mt-2">
    <app-pilot-lookup
      class="w-full"
      formControlName="p1"
      label="P1:"
    ></app-pilot-lookup>
  </div>
  <div class="flex items-baseline mt-2">
    <app-pilot-lookup
      class="w-full"
      formControlName="p2"
      label="P2:"
    ></app-pilot-lookup>
  </div>
  <div class="mt-2 flex flex-col w-full items-end">
    <button class="btn-blue-outline text-sm" type="button">
      <svg-icon key="add" class="inline-block mr-2"></svg-icon> Add Pilot
    </button>
  </div>
  <label for="aircraft" class="font-semibold tracking-wide mt-2"
    >Comments:</label
  >
  <textarea class="w-full mt-2" rows="4" formControlName="comments"></textarea>
</form>
<div class="mt-4 flex justify-end">
  <button class="btn-outline form-group-left" (click)="onCancel()">
    Cancel
  </button>
  <button class="btn-green form-group-right" (click)="onAdd()">
    Add Flight
  </button>
</div>
