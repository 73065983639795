import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-admin-home',
  templateUrl: './app-admin-home.component.html'
})
export class AppAdminHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
