import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../core/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent {
  public email: FormControl;
  public password: FormControl;
  public hasError: boolean = false;
  public errorText: string = "";

  constructor(private router: Router, private authService: AuthService) {
    this.email = new FormControl(null, {
      validators: [Validators.required, Validators.email],
      updateOn: "blur"
    });

    this.password = new FormControl(null, {
      validators: [Validators.required],
    });
  }

  async onLogin() {
    this.hasError = false;
    //TODO: Add and remove spinner while logging in

    try {
      if (!this.email.valid || !this.password.valid) {
        throw new Error("Invalid username or password")
      }

      const credential = await this.authService.signInWithEmailAndPassword(this.email.value, this.password.value);

      if (!credential.user) {
        throw new Error("Invalid user data, please try again")
      }



      this.router.navigate(['']);
    } catch (error: any) {
      this.hasError = true;
      this.errorText = error.message || "An unknown error occurred, please try again later.";
      this.password.reset();
    } finally {

    }
  }
}
