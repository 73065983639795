import { Component, OnInit } from '@angular/core';
import { ClubService } from 'src/app/core/club.service';
import { DialogService } from 'src/app/core/dialog/dialog.service';
import { Club } from 'src/app/models/club.model';
import { AddClubDialogComponent } from './add-club-dialog/add-club-dialog.component';

@Component({
  selector: 'app-clubs',
  templateUrl: './clubs.component.html',
})
export class ClubsComponent implements OnInit {

  public data: Club[] = [];

  constructor(public clubService: ClubService, private dialogService: DialogService) { }

  ngOnInit(): void {

  }

  onAddClub(): void {
    this.dialogService.open(AddClubDialogComponent, {})
  }

}
