<nav class="mb-4 mt-1 inline-flex">
  <button
    class="btn-outline btn-group-left bg-white"
    routerLink="/app-admin/clubs"
    routerLinkActive="text-blue-600 font-medium"
  >
    <svg-icon key="clubs" class="mr-2"></svg-icon>
    Clubs
  </button>
  <button
    class="btn-outline btn-group-right bg-white"
    routerLink="/app-admin/members"
    routerLinkActive="text-blue-600 font-medium"
  >
    <svg-icon key="members" class="mr-2"></svg-icon>
    Users
  </button>
</nav>

<main class="h-full">
  <router-outlet></router-outlet>
</main>
