import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormArray, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ClubService } from 'src/app/core/club.service';
import { Competency, CompetencyGrade } from 'src/app/models/course.model';
import { gradedCompetency } from 'src/app/models/training-report.model';

@Component({
  selector: 'app-competency-grade-selector',
  templateUrl: './competency-grade-selector.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompetencyGradeSelectorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CompetencyGradeSelectorComponent),
      multi: true
    }
  ]
})
export class CompetencyGradeSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {

  @Input() competencyGrades?: CompetencyGrade[];
  @Input() competency!: Competency | undefined;

  public selectedGradeIndex = -1;

  public competencyGradeForm: FormGroup;
  private formValueSubscription!: Subscription;

  constructor(public clubService: ClubService) {
    this.competencyGradeForm = new FormGroup({
      shortResult: new FormControl(null, Validators.required),
      longResult: new FormControl(null, Validators.required),
      colours: new FormControl(null),
      shortName: new FormControl(null, Validators.required),
      longName: new FormControl(null, Validators.required),
      addBehaviours: new FormControl(null),
      behaviours: new FormArray([])
    });
  }

  onTouched: any = () => { };

  ngOnInit(): void {
    this.competencyGradeForm.controls.shortName.setValue(this.competency?.shortName, { emitEvent: false });
    this.competencyGradeForm.controls.longName.setValue(this.competency?.longName, { emitEvent: false });
    this.competencyGradeForm.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.formValueSubscription.unsubscribe();
  }

  onClickGrade(grade: CompetencyGrade, gradeIndex: number) {
    this.onTouched();

    if (gradeIndex !== this.selectedGradeIndex) {
      this.onTouched();

      this.selectedGradeIndex = gradeIndex;
      this.competencyGradeForm.controls.shortResult.setValue(grade.shortResult, { emitEvent: false });
      this.competencyGradeForm.controls.longResult.setValue(grade.longResult, { emitEvent: false });
      this.competencyGradeForm.controls.colours.setValue(grade.colours, { emitEvent: false });
      this.competencyGradeForm.controls.addBehaviours.setValue(grade.addBehaviours, { emitEvent: false });

      this.behaviours.clear({ emitEvent: false });
      if (this.showBehaviours === 1 || this.showBehaviours === -1) {
        for (let count = 0; count < (this.clubService.clubOptions?.minimumBehaviours || 0); count++) {
          this.behaviours.push(new FormControl(null, Validators.required), { emitEvent: false });
        }
      }

      this.competencyGradeForm.updateValueAndValidity();
    }
  }

  writeValue(obj: gradedCompetency): void {
    if (obj) {
      const newGradeIndex = this.competencyGrades?.findIndex((val) => {
        return obj.shortResult === val.shortResult
      });

      if (newGradeIndex) {
        this.selectedGradeIndex = newGradeIndex;
        this.competencyGradeForm.setValue(obj, { emitEvent: false });

        this.behaviours.clear({ emitEvent: false });
        obj.behaviours.forEach(behaviour => {
          this.behaviours.push(new FormControl(behaviour, Validators.required), { emitEvent: false });
        });
      }
    }
  }

  registerOnChange(fn: any): void {
    this.formValueSubscription = this.competencyGradeForm.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate(_: FormControl) {
    return this.competencyGradeForm.valid ? null : { competency: { valid: false } };
  }

  onAddCompetencyBehaviour() {
    const behaviours = <FormArray>this.competencyGradeForm.controls.behaviours;

    if (this.clubService.clubOptions?.maximumBehaviours !== undefined) {
      if (this.clubService.clubOptions.maximumBehaviours === 0 || behaviours.length < this.clubService.clubOptions.maximumBehaviours) {
        behaviours.push(new FormControl(null, Validators.required));
      }
    }
  }
  onRemoveCompetencyBehaviour(behaviourIndex: number) {
    const behaviours = <FormArray>this.competencyGradeForm.controls.behaviours;

    if (this.clubService.clubOptions?.minimumBehaviours !== undefined) {
      if (behaviours.length > this.clubService.clubOptions.minimumBehaviours) {
        behaviours.removeAt(behaviourIndex);
      }
    }
  }

  get showBehaviours() {
    return this.competencyGradeForm.controls.addBehaviours.value || 0;
  }

  get behaviours() {
    return <FormArray>this.competencyGradeForm.controls.behaviours;
  }
}