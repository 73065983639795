import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from 'src/app/core/auth.service';

@Component({
  selector: 'app-forgotten-password',
  templateUrl: './forgotten-password.component.html',
})
export class ForgottenPasswordComponent {
  public email: FormControl;
  public hasError: boolean = false;
  public hasCompleted: boolean = false;

  constructor(private authService: AuthService) {
    this.email = new FormControl(null, {
      validators: [Validators.required, Validators.email],
    });
  }

  async onSendRequest(): Promise<void> {
    if (this.email.valid) {
      this.hasError = false;
      this.email.disable();
      await this.authService.resetPasswordRequest(this.email.value);
      this.hasCompleted = true;
    } else {
      this.hasError = true;
    }
  }
}