<div
  class="card"
  *ngIf="clubService.$clubMembers | async as members; else loading"
  @fadeAnimation
>
  <nav class="mb-2 flex justify-between items-center">
    <button class="btn-green" (click)="onAddMember()">
      <svg-icon key="add-member" class="inline-block mr-1"></svg-icon>
      New Member
    </button>
  </nav>
  <table class="table-auto w-full cursor-default mt-4">
    <thead class="text-center">
      <tr>
        <th>First Name</th>
        <th>Last Name</th>
        <th>Membership No</th>
        <th>Training Stage</th>
        <th>Joined</th>
        <th>Privelage</th>
      </tr>
    </thead>
    <tbody class="text-center">
      <tr *ngFor="let member of members">
        <td>
          {{ member.firstName | titlecase }}
        </td>
        <td>
          {{ member.lastName | titlecase }}
        </td>
        <td>
          {{ member.membershipNo | uppercase }}
        </td>
        <td
          class="px-2 rounded"
          [ngStyle]="{
            color: member.trainingStage.colours.fgColour,
            backgroundColor: member.trainingStage.colours.bgColour
          }"
        >
          {{ member.trainingStage.name }}
        </td>
        <td>
          {{ member.joinedOn.toDate() | date: "dd MMM yy" }}
        </td>
        <td>
          {{ Role[member.role] | titlecase }}
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template class="w-full" #loading>
  <app-loading></app-loading>
</ng-template>
