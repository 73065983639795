import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Timestamp } from '@firebase/firestore-types';
import { TrainingService } from 'src/app/core/training.service';
import { Course } from 'src/app/models/course.model';
import { gradedCompetency, gradedTask, TrainingReport } from 'src/app/models/training-report.model';

@Component({
  selector: 'app-course-summary',
  templateUrl: './course-summary.component.html',
})
export class CourseSummaryComponent implements OnInit {
  public loading = false;
  public taskList: {
    [key: string]:
    {
      name: string,
      subTaskName?: string,
      events: { date: Timestamp, details: gradedTask, report: TrainingReport }[],
    }
  } = {};
  public competencyList: {
    [key: string]: {
      shortCompetencyName: string,
      longCompetencyName: string,
      events: { date: Date, result: gradedCompetency[] }[],
    }
  } = {};
  public course!: Course;
  public records!: TrainingReport[];


  constructor(private trainingService: TrainingService, private router: Router) { }

  async ngOnInit(): Promise<void> {
    this.loading = true;

    if (!window.history.state.course || !window.history.state.userID) {
      // TODO: Consider back to search records with userID?
      this.router.navigate(["search-records"]);
    }

    const [courseDoc, recordCollection] = await Promise.all([
      this.trainingService.getCourse(window.history.state.course.courseID),
      this.trainingService.getCourseRecordCollection(window.history.state.course.courseID, window.history.state.userID)]);

    this.course = <Course>courseDoc.data();
    this.records = <TrainingReport[]>recordCollection.docs.map(i => i.data());

    this.course.tasks.forEach((task) => {
      if (task.subTasks && task.subTasks.length > 0) {
        task.subTasks.forEach((subTask) => {
          this.taskList[task.id + "-" + subTask.id] = { name: task.name, subTaskName: subTask.name, events: [] };
        });
      } else {
        this.taskList[task.id] = { name: task.name, events: [] };
      }
    });

    this.records.forEach(record => {
      record.gradedTasks.forEach(gradedTask => {
        if (!gradedTask.subTask) {
          this.taskList[gradedTask.id].events.push({ date: record.date, details: gradedTask, report: record });
        } else {
          this.taskList[gradedTask.id + "-" + gradedTask.subTask.id].events.push({ date: record.date, details: gradedTask, report: record })
        }
      });
    })

    this.loading = false;
  }


}
