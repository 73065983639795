import { formatDate } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { AuthService } from "./auth.service";

@Pipe({ name: "userDate" })
export class UserDatePipe implements PipeTransform {
    constructor(private authService: AuthService) {
        //TODO: Get user option
    }

    transform(value: any, ...args: any[]) {
        if (value.toDate) {
            value = value.toDate()
        }
        return formatDate(value, "mediumDate", "en-US");
    }
}