<div class="card mb-4" @fadeAnimation>
  <h2>Courses</h2>
  <ng-container *ngIf="$user | async as user; withLoading">
    <ul class="text-sm flex items-start mt-2">
      <li
        class="btn-outline mr-3"
        *ngFor="let course of user.courses"
        [appDisplayColour]="course.colour"
        [routerLink]="['/course-summary']"
        [state]="{ course: course, userID: userID }"
      >
        {{ course.name }}
      </li>
    </ul>
  </ng-container>
</div>

<div class="card" @fadeAnimation>
  <h2>Training Events</h2>
  <ng-container *ngIf="$reports | async as reports; withLoading">
    <table class="w-full table-auto mt-4">
      <thead class="text-left">
        <tr>
          <th>Date</th>
          <th>Course</th>
          <th class="collapse">Reg</th>
          <th>Instructor</th>
          <th class="collapse">Club</th>
        </tr>
      </thead>
      <tbody>
        <tr
          class="cursor-pointer hover:underline"
          (click)="onClickReport(report)"
          *ngFor="let report of reports"
        >
          <td>{{ report.date.toDate() | date: "dd MMM yy" }}</td>
          <td class="rounded px-2" [appDisplayColour]="report.colour">
            {{ report.courseName }}
          </td>
          <td class="collapse">{{ report.aircraft?.reg }}</td>
          <td>
            {{ report?.instructorDetails?.firstName | titlecase }}
            {{ report?.instructorDetails?.lastName | titlecase }}
          </td>
          <td class="collapse">{{ report.clubName }}</td>
        </tr>
      </tbody>
    </table>
  </ng-container>
</div>
