import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { FormGroup, FormArray, FormControl, AbstractControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { fadeAnimation } from 'src/app/core/animations';
import { AuthService } from 'src/app/core/auth.service';
import { DialogService } from 'src/app/core/dialog/dialog.service';
import { TrainingService } from 'src/app/core/training.service';
import { Course } from 'src/app/models/course.model';
import { DisplayColour } from 'src/app/models/display-colour.model';
import { CoursePickerDialogComponent } from '../course-picker-dialog/course-picker-dialog.component';
import { v4 as uuid } from 'uuid';

@Component({
  selector: 'app-course-details',
  templateUrl: './course-details.component.html',
  animations: [fadeAnimation]
})
export class CourseDetailsComponent implements OnInit {

  form: FormGroup;
  private courseID?: string;
  public createMode: boolean = true;

  constructor(private fs: AngularFirestore, private authService: AuthService, private trainingService: TrainingService, private router: Router, private dialogService: DialogService) {
    this.form = new FormGroup({
      tasks: new FormArray([]),
      taskGrades: new FormArray([]),
      competencies: new FormArray([]),
      competencyGrades: new FormArray([]),
      includeNarrative: new FormControl(true),
      includeReg: new FormControl(true),
      includeReview: new FormControl(true),
      includeTasks: new FormControl(true),
      includeCompetencies: new FormControl(true),
      hidden: new FormControl(false),
      clubID: new FormControl(""),
      courseName: new FormControl(null, [Validators.required]),
      index: new FormControl(null),
      colours: new FormControl(null)
    })
  }

  ngOnInit(): void {
    if (window.history.state.course) {
      this.createMode = false;
      const course = window.history.state.course as Course;
      this.courseID = course.courseID;
      this.form.patchValue(course);

      course.tasks.forEach(task => {
        this.onAddTask(task.name, task.subTasks, task.id)
      })

      course.taskGrades.forEach(taskGrade => {
        this.onAddTaskGrade(taskGrade.shortResult, taskGrade.longResult, taskGrade.colours)
      })

      course.competencies.forEach(competency => {
        this.onAddCompetency(competency.shortName, competency.longName, competency.behaviours)
      })

      course.competencyGrades.forEach(competencyGrade => {
        this.onAddCompetencyGrade(competencyGrade.shortResult, competencyGrade.longResult, competencyGrade.addBehaviours, competencyGrade.colours)
      })
    }
  }

  onCreateCourse(): void {
    if (this.form.valid) {
      if (this.createMode) {
        const clubID = this.authService.user?.selectedClub?.clubID;

        if (clubID) {
          this.form.controls.clubID.setValue(clubID);
          this.form.controls.index.setValue(0); //FIXME with correct index
          this.fs.collection("courses").add(this.form.value);
          this.router.navigate(["club-admin", "courses"])
        } else {
          throw new Error("No club ID provided");
        }
      } else {
        this.fs.collection("courses").doc(this.courseID).update(this.form.value);
        this.router.navigate(["club-admin", "courses"])
        // TODO: Error handling for no doc
      }
    }
    // TODO: If not valid
  }

  onCopy(location: string) {
    this.dialogService.open(CoursePickerDialogComponent, {}).afterClosed.subscribe((res: Course) => {

    });
  }

  onAddTask(name: string = "", subTasks: { name: string, id: string }[] = [], id?: string) {
    const subtaskControls: AbstractControl[] = [];
    subTasks.forEach(subtask => {
      if (!subtask.id || subtask.id === "") {
        subtask.id = uuid();
      }
      subtaskControls.push(new FormGroup({ name: new FormControl(subtask.name, Validators.required), id: new FormControl(subtask.id) }))
    })
    if (!id || id === "") {
      id = uuid();
    }
    this.tasks.push(new FormGroup({ name: new FormControl(name, Validators.required), id: new FormControl(id, Validators.required), subTasks: new FormArray(subtaskControls) }));
  }

  onAddSubTask(idx: number) {
    const task = <FormArray>this.tasks.at(idx).get("subTasks");
    task.push(new FormGroup({ name: new FormControl(null, Validators.required), id: new FormControl(uuid()) }))
  }

  onAddTaskGrade(shortResult: string = "", longResult: string = "", colours?: DisplayColour) {
    this.taskGrades.push(new FormGroup({
      shortResult: new FormControl(shortResult),
      longResult: new FormControl(longResult),
      colours: new FormControl({
        fgColour: colours?.fgColour || null,
        bgColour: colours?.bgColour || null,
      })
    }));
  }

  onAddCompetency(shortName: string = "", longName: string = "", behaviours: string[] = []) {
    const behaviourControls: AbstractControl[] = [];
    behaviours.forEach(behaviour => {
      behaviourControls.push(new FormControl(behaviour))
    })
    this.competencies.push(new FormGroup({ shortName: new FormControl(shortName), longName: new FormControl(longName), behaviours: new FormArray(behaviourControls) }));
  }

  onAddBehaviour(idx: number) {
    const behaviours = <FormArray>this.competencies.at(idx).get("behaviours");
    behaviours.push(new FormControl(null))
  }


  onAddCompetencyGrade(shortResult: string = "", longResult: string = "", addBehaviours: number = 0, colours?: DisplayColour) {
    this.competencyGrades.push(new FormGroup({
      shortResult: new FormControl(shortResult),
      longResult: new FormControl(longResult),
      addBehaviours: new FormControl(addBehaviours),
      colours: new FormControl({
        fgColour: colours?.fgColour || null,
        bgColour: colours?.bgColour || null,
      })
    }));
  }

  onRemove(array: FormArray, idx: number) {
    array.removeAt(idx);
  }

  onRemoveSubTask(i: number, j: number) {
    const task = <FormArray>this.tasks.at(i).get("subTasks");
    task.removeAt(j);
  }

  onRemoveBehaviour(i: number, j: number) {
    const behaviour = <FormArray>this.competencies.at(i).get("behaviours");
    behaviour.removeAt(j);
  }

  get tasks(): FormArray {
    return <FormArray>this.form.get('tasks');
  }
  getSubTasks(idx: number): FormArray {
    return <FormArray>this.tasks.at(idx).get('subTasks');
  }
  get taskGrades(): FormArray {
    return <FormArray>this.form.get('taskGrades');
  }

  get competencies(): FormArray {
    return <FormArray>this.form.get('competencies');
  }
  getBehaviours(idx: number): FormArray {
    return <FormArray>this.competencies.at(idx).get('behaviours');
  }
  get competencyGrades(): FormArray {
    return <FormArray>this.form.get('competencyGrades');
  }

  public move(array: FormArray, currentIndex: number, shift: number) {
    let newIndex: number = currentIndex + shift;

    if (newIndex === -1 || newIndex >= array.length) {
      return;
    }

    const currentGroup = array.at(currentIndex);
    array.removeAt(currentIndex);
    array.insert(newIndex, currentGroup)
  }
}
