<h2>{{ config.data.title }}</h2>
<p>{{ config.data.message }}</p>
<div
  class="flex items-baseline justify-end mt-4"
  *ngIf="config.data.type === 'yesno'"
>
  <button class="btn btn-warning mr-2" (click)="onClose(false)">No</button>
  <button class="btn btn-success" (click)="onClose(true)">Yes</button>
</div>
<div
  class="flex items-baseline justify-end mt-4"
  *ngIf="config.data.type === 'okcancel'"
>
  <button class="btn mr-2" (click)="onClose(false)">Cancel</button>
  <button class="btn btn-primary" (click)="onClose(true)">Ok</button>
</div>
