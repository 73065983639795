import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from './auth.service';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Course } from '../models/course.model';
import { TrainingReport } from '../models/training-report.model';
import { MemberInfo } from '../models/club.model';

@Injectable({
  providedIn: 'root'
})
export class TrainingService {

  public $clubCourses: Observable<Course[] | undefined>;
  public $clubCoursesWithHidden: Observable<Course[] | undefined>;
  private addRecordRequest = this.fns.httpsCallable('addTrainingRecord');

  constructor(private fs: AngularFirestore, private authService: AuthService, private fns: AngularFireFunctions) {

    this.$clubCourses = this.authService.$userDoc.pipe(switchMap((userDoc) => {
      if (userDoc === undefined) return of(undefined);
      return this.fs.collection<Course>("courses",
        ref => ref
          .where("clubID", "==", userDoc.clubs[userDoc.selectedClubIndex].clubID)
          .orderBy("index"))
        .valueChanges({ idField: 'courseID' })
    }));

    this.$clubCoursesWithHidden = this.authService.$userDoc.pipe(switchMap((userDoc) => {
      if (userDoc === undefined) return of(undefined);
      return this.fs.collection<Course>("courses",
        ref => ref
          .where("clubID", "==", userDoc.clubs[userDoc.selectedClubIndex].clubID)
          .orderBy("index"))
        .valueChanges({ idField: 'courseID' })
    }));

  }

  createRecord(report: TrainingReport, createForSelf: boolean, otherPilot: MemberInfo) {
    const clubDetails = this.authService.user?.selectedClub;
    return this.addRecordRequest({ form: report, createForSelf, otherPilot, clubDetails });
  }

  getCourse(courseID: string) {
    return firstValueFrom(this.fs.doc(`courses/${courseID}`).get());
  }

  getCourseRecordCollection(courseID: string, userID: string) {
    return firstValueFrom(this.fs.collection(`users/${userID}/training`, ref => ref
      .where("courseID", "==", courseID)
      .orderBy("date")).get());
  }
}
