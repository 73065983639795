import { Component, OnInit } from '@angular/core';
import { DialogService } from 'src/app/core/dialog/dialog.service';
import { CreateEventTypeDialogComponent } from './create-event-type-dialog/create-event-type-dialog.component';

@Component({
  selector: 'app-club-bookings',
  templateUrl: './club-bookings.component.html',
})
export class ClubBookingsComponent implements OnInit {

  constructor(private dialogService: DialogService) { }

  ngOnInit(): void {
  }

  onAddEventType() {
    this.dialogService.open(CreateEventTypeDialogComponent, {}).afterClosed.subscribe((res) => {
      //TODO: Something here!
    });
  }
}